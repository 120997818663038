import {CHECK_BASIC_AUTHENTICATION, CREATE_SESSION, INFO_SHOW, LOAD_END} from "../types";
import netcoServer from "../../apis/netcoServer";
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import signPendingFiles from "../files/signPendingFiles";
import getUserInfo from "../profile/getUserInfo";
import getValidOperations from "../signature/getValidOperations";
import loadingCall from "../helpers/loadingCall";
import logoutUser from "./logoutUser";

const checkAppCodeAuthentication= (formValues, uid,hideMenu=true,logout=false,usePAD=true, isNewUser=false, receptor=null) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/LoginService/checkBasicAuthentication', formValues));
  if (response === undefined) {
    dispatch({type: INFO_SHOW, payload: infoPayload('error', 'Error contactando el servidor')});
    dispatch({type: LOAD_END});
  } else if (response.data.success) {
    sessionStorage.setItem("logged", true);
    dispatch({type:CREATE_SESSION,payload:{hasSession:true}});
    const data=response.data;
    let payload = {
      auth: {
        admin: data.admin,
        txAuth: data.txAuth,
        requiresCert: data.requiresCert,
        detail: data.detail,
        entrust: data.entrust,
        netco: data.netco,
        ldap: data.ldap,
        enableecom:data.enableecom,
        userName:data.userName,
        hideMenu:hideMenu,
        logoutAfter:logout,
        limitFiles:data.limitFiles,
        params:{usePAD,isNewUser,receptor}
      },
      info: {
        message: 'Bienvenido',
        type: 'success'
      }
    };
    if(payload.auth.detail!=="")
      payload.info.message=payload.auth.detail;
    else
      payload.info.message=null;
    dispatch({type:CHECK_BASIC_AUTHENTICATION,payload:payload});
    response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getPendingFilesInformation', {uids:uid}));
    if (response === undefined) {
      dispatch({type: INFO_SHOW, payload: infoPayload('error', 'Error contactando el servidor')});
      dispatch({type: LOAD_END});
    } else if (response.data.success) {
      let filesInfo=response.data.filesInfo.pendingFilesInfo;
      if(filesInfo && filesInfo.length>0)
      {
        await dispatch(getUserInfo());
        await dispatch(getValidOperations());
        const result=await dispatch(signPendingFiles(filesInfo));
        //if error
        if(result)
          dispatch(logoutUser(false,false,true));
      } else {
        dispatch({type: INFO_SHOW, payload: infoPayload('error', 'No existe el archivo!')});
        dispatch({type: LOAD_END});
        dispatch(logoutUser(null,false,true));
      }
    } else if (!response.data.success) {
      dispatch({type: INFO_SHOW, payload: infoPayload('error', response.data.detail)});
      dispatch({type: LOAD_END});
      dispatch(logoutUser(null,false,true));
    }
  } else if (!response.data.success) {
    dispatch({type: INFO_SHOW, payload: infoPayload('error', response.data.detail)});
    dispatch({type: LOAD_END});
  }
};
export default checkAppCodeAuthentication;