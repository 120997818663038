import React from 'react';
import { connect } from "react-redux";
import {Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import { Button, Card, Checkbox, Col, Row, Spin, Tooltip, Select, Menu } from "antd";
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import './SignPreviewer.css';
import getUserImageToShow from '../../../actions/profile/getUserImageToShow';
import updateUserCoords from '../../../actions/signature/updateUserCoords';
import modalShow from "../../../actions/modal/modalShow";
import modalDismiss from "../../../actions/modal/modalDismiss";
import decreasePendingFiles from "../../../actions/signature/decreasePendingFiles";
import {
  MESSAGE_MODAL,
  PDF_PASSWORD_MODAL, REJECT_MODAL, SIGNATURE_CHOOSER_MODAL, SIGN_AND_SHARE,
  SIGNATURE_MODAL,
  SIGNATURE_UPIMAGE_MODAL,
  ZOOM_MODAL
} from "../../Feedback/Modal/types";
import signature from "../../../const/signature";
import redirect from "../../../actions/auth/redirect";
import general from "../../../const/general";
import hideMenu from "../../../actions/signature/hideMenu";
import { REDIRECT_DOCS_ROUTE, ROOT_ROUTE, SIGNATURE_SIGN_ROUTE } from "../../Root/routes";
import getUserInfo from "../../../actions/profile/getUserInfo";
import getValidOperations from "../../../actions/signature/getValidOperations";
import getPDFPasswordInformation from "../../../actions/files/getPDFPasswordInformation";
import { DragOutlined } from "@ant-design/icons";
import profile from "../../../const/profile";
import clearFiles from "../../../actions/files/clearFiles";
import PDFPaginator from "../../Helpers/PDFPaginator";
import { useSignaturePAD } from "../../../apis/generalValues";
import getHWImage from '../../../actions/signature/getHWImage';
import updateHWImage from '../../../actions/signature/updateHWImage';
import SubmitButton from "../../Helpers/SubmitButton";
import updateUserParam from "../../../actions/profile/updateUserParam";
import setLoadingPad from "../../../actions/signature/setLoadingPad";
import deletePendingFiles from '../../../actions/files/deletePendingFiles';
import logoutUser from "../../../actions/auth/logoutUser";
import showMessage from "../../../actions/info/showMessage";
import hideAllMenu from '../../../actions/info/hideAllMenu';
import { getDictionaryMetadata, isPhoneNumber, renderCopyRight, showFull } from '../../Helpers/utils';
import { Typography } from 'antd';
import load_files from "../../../const/load_files";
import { push } from "connected-react-router";
import { HOME_ROUTE } from "../../Root/routes";
import updateUserSettings from '../../../actions/profile/updateUserSettings';
import NewGraphicChooser from '../../NewGraphicChooser';
import { ReactComponent as ImageOrTextIcon } from '../../../resources/img/NewIcons/previewer/image_text.svg';
import { ReactComponent as FingerprintIcon } from '../../../resources/img/NewIcons/previewer/fingerprint.svg';
import { ReactComponent as InvisibleIcon } from '../../../resources/img/NewIcons/previewer/invisible.svg';
import { ReactComponent as QRIcon } from '../../../resources/img/NewIcons/previewer/qr.svg';
import { ReactComponent as HandwrittenIcon } from '../../../resources/img/NewIcons/previewer/handwritten.svg';
import nextFileIcon from '../../../resources/img/NewIcons/paginator/next-file.svg'
import prevFileIcon from '../../../resources/img/NewIcons/paginator/prev-file.svg'
import browserFileIcon from '../../../resources/img/NewIcons/paginator/browser-file.svg'
import settingsIcon from '../../../resources/img/NewIcons/previewer/settings.svg'
import closeIcon from '../../../resources/img/NewIcons/previewer/close.svg'
import bookOpenIcon from '../../../resources/img/NewIcons/previewer/book-open.svg'
import infoIcon from '../../../resources/img/NewIcons/previewer/info.svg'
import arrowLeftIcon from '../../../resources/img/NewIcons/previewer/arrow-left.svg'
import { Drawer } from 'antd';
import handleBase64ZipResponse from "../../../actions/helpers/handleBase64ZipResponse";
import getAllMetadata from '../../../actions/admin/getAllMetadata';
import getPendingFileInformation from '../../../actions/files/getPendingFileInformation';
import signed_files from '../../../const/signed_files';
import moment from "moment";

pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.js";
class SignPreviewer extends React.Component {

  blankImage = 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII='; loadingPad
  dottedBorderpx = 0;
  handleSize = 30;
  maxZoom = 500;
  zoomIncrement = 50;
  state = {
    numPages: 0,
    pageNumber: null,
    xCord: 0,
    yCord: 0,
    width: 0,
    height: 0,
    currentFile: 0,
    passwords: {},
    init: false,
    loadingPad: false,
    padSocket: null,
    padAttempts: 0,
    padSocketOpen: false,
    padButton: false,
    padTimer: null,
    padImage: '',
    padData: null,
    padOverwrite: false,
    fingerTimer: null,
    wsfingerclosed: false,
    pageHeight: 0,
    pageWidth: 0,
    fingerSocket: null,
    zoom: 100.0,
    newCoords: [],
    processed: [],
    showSignatureOptions: false,
    showOptions: false,
    showFiles: false,
    showSignatures: false,
    showInfo: false,
    metadataAll: {},
    moreInfoPDF: {}
  };

  constructor(props) {
    super(props);
    this.myDiv = React.createRef();
    this.mySelectedDiv = React.createRef();
  }


  componentDidMount() {
    const params = this.props.params;
    if (!this.props.signatureFiles.entries) {
      this.props.redirect(ROOT_ROUTE);
      return;
    }
    if(params && params.isNewUser==="true" && params.receptor && isPhoneNumber(params.receptor)){
      this.props.updateUserSettings({cellPhone:params.receptor})
    }
    this.props.hideAllMenu(true)
    this.getImageToShow();
    this.props.getAllMetadata();
    this.getPendingFileInfo();
  };

  getPendingFileInfo = async () => {
    const file = this.props.signatureFiles.entries[this.state.currentFile];
    if (file.uid) {
      const moreInfoPDF = await this.props.getPendingFileInformation({ uids: file.uid });
      this.setState({ moreInfoPDF })
    }
  }

  disablePad = () => {
    setTimeout(() => this.setState({ loadingPad: false }, () => {
      this.props.setLoadingPad(this.state.padButton ? false : undefined);
    }
    ), 15000);
  };

  initDevices = () => {
    let usePAD = true;
    if (this.props.auth && this.props.auth.params && typeof (this.props.auth.params.usePAD) !== 'undefined')
      usePAD = this.props.auth.params.usePAD;
    if (this.props.user.useImage === '2' && (!this.state.padSocket || !this.state.padSocketOpen) && String(usePAD) === 'true' && useSignaturePAD) {
      this.setState({ loadingPad: true }, () => this.props.setLoadingPad(true));
      this.handlePadConnection();
      this.disablePad();
    }
    if (this.props.user.useImage === '5' && this.state.fingerTimer === null && !this.state.wsfingerclosed)
      this.initFingerprint();
  };

  componentWillUnmount() {
    if (this.props.location.pathname === SIGNATURE_SIGN_ROUTE)
      this.props.clearFiles();
    this.props.hideAllMenu(false)
    this.closeDevices(3983);
    this.clearImageQR(false)
  }

  closeDevices = (padCode = 3905) => {
    const padTimer = this.state.padTimer;
    const fingerTimer = this.state.fingerTimer;
    const padSocket = this.state.padSocket;
    const fingerSocket = this.state.fingerSocket;
    const ownRef = this;
    return new Promise(async function (resolve) {
      if (padTimer)
        clearInterval(padTimer);
      if (fingerTimer)
        clearInterval(fingerTimer);
      if (padSocket)
        padSocket.close(padCode, 'unmount');
      if (fingerSocket)
        fingerSocket.close(4996, 'Unmount');
      ownRef.setState({ fingerTimer: null, padSocket: null, fingerSocket: null, padTimer: null, wsfingerclosed: false }, () => resolve())
    });
  }

  initFingerprint() {
    let fingerTimer, socket;
    socket = new WebSocket("wss://signsocket.stepover.com:62045/netcoWebSocket/wsocketendpoint");
    socket.onopen = () => {
    };
    socket.onmessage = (msg) => {
      switch (msg.data) {
        case "onOpen":
          fingerTimer = setInterval(() => {
            if (socket.readyState === socket.OPEN) {
              socket.send('getImage');
            }
          }, 500);
          this.setState({ fingerTimer: fingerTimer, fingerSocket: socket });
          break;
        case "noSensor":
          if (socket.readyState === socket.OPEN)
            socket.close(4998, 'Not found');
          console.log("Fingerprint reader not found");
          break;
        default:
          window.clearInterval(this.state.fingerTimer);
          if (socket.readyState === socket.OPEN)
            socket.close(4997, 'Start over');
          this.updateFingerprint(msg.data);
      }
    };
    socket.onerror = () => {
      socket.close(4998, 'Error');
    };
    socket.onclose = (msg) => {
      console.log("Close code: " + msg.code);
      this.setState({ wsfingerclosed: true });

    }
  }

  updateFingerprint = (fingerData) => {
    this.props.signatureFiles.image = fingerData;
    this.setState({ padImage: fingerData, fingerTimer: null, fingerSocket: null }, () => this.handleSubmit(false));
  };


  handlePadConnection = () => {
    this.setState({ padAttempts: this.state.padAttempts + 1 }, () => {
      if (this.state.padAttempts < 30) {
        this.connectToPad();
      } else {
        console.log('Could not reconnect to PAD');
      }
    });
  };

  resetPad = () => {
    clearInterval(this.state.padTimer);
    if (this.state.padSocket)
      this.state.padSocket.close();
    this.setState({ loadingPad: true, padButton: false, padTimer: null, padImage: null },
      () => {
        this.props.updateHWImage(JSON.stringify({ state: "cancelledPad" }))
        this.props.setLoadingPad(true);
        this.handlePadConnection()
      });
  }

  connectToPad = () => {
    let socket = null;
    try {
      setTimeout(() => {
        socket = new WebSocket("wss://signsocket.stepover.com:57357/signsocket/");
        socket.onopen = () => {
          this.setState({ padSocketOpen: true, padAttempts: 0 }, () => {
            try {
              this.state.padSocket.send(JSON.stringify({ type: 'start', data: {} }));
              this.state.padSocket.send(JSON.stringify({ type: 'executeDeviceSearch', data: { messageId: 11111111 } }));
            }
            catch (e) {
              console.log("Error connecting: " + e);
            }

          });
        };
        socket.onmessage = async (msg) => {
          let json = JSON.parse(msg.data);
          if (json.heartbeat)
            return;
          switch (json.type) {
            case "onViewerPageShowing":
              this.setState({ padButton: true, loadingPad: false }, () => this.props.setLoadingPad(false));
              break;
            case "onStaticAppletCaptureStarted":
              this.handleStartPadSigning();
              break;
            case "onStaticAppletSignatureFinished":
              this.handleSubmit(true);
              this.props.updateHWImage(JSON.stringify({ state: "finishedPad" }))
              break;
            case "onStaticAppletSignatureRepeat":
              clearInterval(this.state.padTimer);
              if (this.state.padSocket)
                this.state.padSocket.close();
              this.setState({ loadingPad: true, padImage: null },
                () => {
                  this.props.updateHWImage(JSON.stringify({ state: "cancelledPad" }))
                  this.props.setLoadingPad(true)
                });
              this.handlePadConnection();
              this.disablePad();
              break;
            case "onStaticAppletSignatureCancel":
              this.resetPad()
              break;
            case "onStaticAppletGetImage":
              let mode = json.data.value1;
              if (mode === 1) {
                this.state.padSocket.send(JSON.stringify({ type: 'onAppletGetImageResponse', data: { messageId: json.data.messageId, response: "" } }));
              } else {
                if (this.props.signatureFiles.entries) {
                  let base64File = this.myDiv.current.firstChild.firstChild.firstChild.toDataURL('image/jpeg').replace("data:image/jpeg;base64,", "");
                  this.state.padSocket.send(JSON.stringify({ type: 'onAppletGetImageResponse', data: { messageId: json.data.messageId, response: base64File } }));
                } else {
                  this.state.padSocket.close();
                }
              }
              break;
            case "response":
              switch (json.messageId) {
                case '11111111':
                  this.handleInitPad(json.data.ret);
                  break;
                case '22222222':
                  this.handleViewingPad(json.data.ret);
                  break;
                case '33333333':
                  this.handleStartPadSigning();
                  break;
                case '99999999':
                  this.handlePadImage(json.data.ret);
                  break;
                default:
                  if (json.data && json.data.ret) {
                    if (json.data.ret === 'ok')
                      break;
                    if (json.data.ret === 1)
                      this.handleInitPad(json.data.ret);
                    else
                      this.handlePadImage(json.data.ret);
                  }
                  break;
              }
              break;
            default: break;
          }
        };
        socket.onerror = () => {
          //console.log('ERROR EN EL SOCKET', msg);
          if (this.state.padSocket)
            this.state.padSocket.close();
          if (!this.state.padSocketOpen)
            this.handlePadConnection();
        }
        socket.onclose = (msg) => {
          if (msg.code !== 3983)
            this.setState({ padSocketOpen: false });
        }
        this.setState({ padSocket: socket });
      }, this.state.padAttempts === 0 ? 0 : 1000);
    } catch (e) {
      console.log('Error encontrando el socket vamos a reintentar');
      this.handlePadConnection();
    }
  };

  handlePadImage = (image, data = null) => {
    this.setState({ padImage: image, padData: data });
  };

  handleStartPadSigning = () => {
    let timer = setInterval(() => {
      this.state.padSocket.send(JSON.stringify({ type: 'getSignatureImage', data: { 'messageId': 99999999, 'width': 40, 'height': 20, 'withAlpha': false } }));
    }, 500);
    this.setState({ padTimer: timer });
  };

  onStartPadSigning = () => {
    this.setState({ padButton: false }, () => {
      this.state.padSocket.send(JSON.stringify({
        type: 'startSigning',
        data: {
          'messageId': 33333333,
          'x': 71, 'y': 684,
          'width': 200,
          'height': 100,
          'resolution': 7200,
          'page': 0,
          'withHashDialog': false,
          'signSession': "signSession",
          "signatureTimeout": 1800000
        }
      }));
      this.props.updateHWImage(JSON.stringify({ state: "drawingPad" }))
    });
  };

  handleInitPad = result => {
    switch (result) {
      case 1:
        this.state.padSocket.send(JSON.stringify({ type: 'startViewing', data: { 'messageId': 22222222, 'pages': 1, 'resolution': 150 } }));
        break;
      default:
        console.log("Websocket encontrado pero no pad, revisar conexion");
        this.setState({ loadingPad: false }, () => this.props.setLoadingPad(undefined));
        break;
    }
  };

  handleViewingPad = result => {
    switch (result) {
      case 'ok':
        setTimeout(() => {
          this.setState({ padButton: true });
        }, 5000);
        break;
      default:
        break;
    }
  };

  setImageSize = () => {
    if (this.hasSignFields()) {
      this.lockCoords();
      return;
    }

    switch (this.props.user.useImage) {
      case '1':
        if (this.props.user.reason && this.props.user.reason !== '')
          this.setStateImageSize(300, 100);
        else
          this.setStateImageSize(300, 60);
        break;
      case '2':
        //this.setStateImageSize(200,100);
        this.setStateImageSize(170, 75);
        break;
      case '3':
        if (this.props.user.reason && this.props.user.reason !== '')
          this.setStateImageSize(200, 100);
        break;
      case '4':
        if (this.props.user.reason && this.props.user.reason !== '')
          this.setStateImageSize(200, 200);
        else
          this.setStateImageSize(100, 100);
        /* let i = new Image();
         i.onload = () =>
         {
           let width=i.width,height=i.height;
           this.setStateImageSize(width,height);
         };
         i.src = `data:image/png;base64, ${data.signatureFiles.image}`;*/
        break;
      case '5':
        if (this.props.user.reason && this.props.user.reason !== '')
          this.setStateImageSize(200, 200);
        else
          this.setStateImageSize(91, 100);
        break;
      default:
        break;
    }
  };

  setStateImageSize = (width, height) => {
    if (this.state.width === 0 && this.state.height === 0)
      this.setState({ width: width, height: height }, this.fixDraggableSize);
  };

  getImageToShow = () => {
    this.props.getUserImageToShow(null, () => this.setState({ width: 0, height: 0 }, () => { this.setImageSize(); this.initDevices() }));
  };

  clearImageQR = (getImageToShow = true) => {
    clearInterval(this.timerGetImage);
    this.timerGetImage = null;
    this.props.updateHWImage();
    if (getImageToShow)
      this.props.getUserImageToShow();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (((this.props.user.useImage && prevProps.user.useImage && prevProps.user.useImage !== this.props.user.useImage) ||
      (this.props.user.operation && prevProps.user.operation && prevProps.user.operation !== this.props.user.operation) ||
      (this.props.user.reason !== prevProps.user.reason)))
      this.closeDevices().then(this.getImageToShow);

    if (prevProps.signatureFiles.infoImageQR.base64 !== this.props.signatureFiles.infoImageQR.base64 && this.props.signatureFiles.infoImageQR.base64) {
      this.handlePadImage(this.props.signatureFiles.infoImageQR.base64)
    }
    if (prevProps.signatureFiles.infoImageQR.state !== this.props.signatureFiles.infoImageQR.state) {
      if (this.props.signatureFiles.infoImageQR.state === 'finished') {
        this.setState({ padData: null }, this.handleSubmit);
      }
      if (this.props.signatureFiles.infoImageQR.state === 'cancelled') {
        this.setState({ padData: null }, this.clearImageQR);
      }
    }
    if (this.props.metadata !== prevProps.metadata) {
      this.setState({ metadataAll: getDictionaryMetadata(this.props.metadata, this.props.language) })
    }
    if (this.props.signatureFiles.entries && prevProps.signatureFiles.entries[prevState.currentFile] !== this.props.signatureFiles.entries[this.state.currentFile]) {
      this.getPendingFileInfo()
    }
  }

  getPDFInfo = () => {
    const entries = this.props.signatureFiles.entries;
    if (!entries)
      return null;
    const pdfInfo = entries[this.state.currentFile].pdfInfo;
    if (!pdfInfo)
      return null;
    return pdfInfo;
  };

  hasSignFields = () => {
    const pdfInfo = this.getPDFInfo();
    if (!pdfInfo)
      return false;
    return !!(pdfInfo.dbPage || pdfInfo.coords);

  };

  getAllCoordsToLock = () => {
    const allCoords = [];
    const pdfInfo = this.getPDFInfo();
    if (!pdfInfo)
      return allCoords;

    const coords = pdfInfo.coords;
    if (coords && coords.length > 0)
      return coords;
    if (pdfInfo.dbPage)
      return [{ dbPage: pdfInfo.dbPage, llx: pdfInfo.llx, lly: pdfInfo.lly, urx: pdfInfo.urx, ury: pdfInfo.ury }];
  };

  getFirstCoordsToLock = () => {
    const allCoords = this.getAllCoordsToLock();
    if (!allCoords || allCoords.length <= 0)
      return null;
    return allCoords[0];
  }

  getCoordsToLock = () => {
    const allCoords = this.getAllCoordsToLock();
    if (!allCoords || allCoords.length <= 0)
      return null;
    const pageNumber = this.state.pageNumber;
    if (!pageNumber)
      return null;
    const pageCoordArray = allCoords.filter(coord => coord && coord.dbPage === pageNumber);
    if (pageCoordArray.length <= 0)
      return null;
    return pageCoordArray;
  };

  lockCoords = () => {
    if (this.hideImage())
      return false;
    const allCoords = this.getCoordsToLock();
    if (!allCoords)
      return false;
    console.info("Locked coords", allCoords);
    const pageWidth = this.myDiv.current.offsetWidth - this.handleSize - 2;
    const pageHeight = this.myDiv.current.offsetHeight - 2;
    const newCoords = allCoords.map((coord) => {
      const xCord = parseFloat(coord.llx);
      const yCord = parseFloat(pageHeight) - parseFloat(coord.ury);
      const width = parseFloat(coord.urx) - parseFloat(coord.llx);
      const height = parseFloat(coord.ury) - parseFloat(coord.lly);
      const newCoord = this.fixCoordsDraggableSize(xCord, yCord, width, height, pageWidth, pageHeight);
      newCoord.dbPage = coord.dbPage;
      return newCoord;
    });
    const coords = allCoords[0];
    this.setState({
      xCord: parseFloat(coords.llx),
      yCord: parseFloat(pageHeight) - parseFloat(coords.ury),
      width: parseFloat(coords.urx) - parseFloat(coords.llx),
      height: parseFloat(coords.ury) - parseFloat(coords.lly),
      pageHeight: pageHeight,
      pageWidth: pageWidth,
      newCoords: newCoords
    });
    return true;
  }

  updatePDFPasswordInformation = (result, { numPages }) => {
    this.props.signatureFiles.entries[this.state.currentFile].pdfInfo = result;
    this.loadDocumentInfo({ numPages });
  }

  onDocumentLoadSuccess = (result) => {
    const numPages = result.numPages;
    const file = this.props.signatureFiles.entries[this.state.currentFile];
    if (this.state.passwords && this.state.passwords[file.name])
      this.props.getPDFPasswordInformation(file.uid, this.state.passwords, this.updatePDFPasswordInformation, { numPages });
    else
      this.loadDocumentInfo({ numPages });
  };

  loadDocumentInfo = ({ numPages }) => {
    let page = 1;
    const coords = this.getFirstCoordsToLock();
    if (coords && coords.dbPage)
      page = coords.dbPage;
    else if (this.props.user.pageNumber)
      page = this.props.user.pageNumber;
    if (page > numPages)
      page = numPages;
    this.setState({ numPages, pageNumber: page });
  };

  onPageLoadSuccess = () => {
    const pageWidth = this.myDiv.current.offsetWidth - this.handleSize - 2;
    const pageHeight = this.myDiv.current.offsetHeight - 2;
    if (!this.lockCoords()) {
      let newState = { pageHeight: pageHeight, pageWidth: pageWidth };
      if (!this.state.init) {
        newState.xCord = parseInt(this.props.user.llx) + this.dottedBorderpx;
        newState.yCord = (pageHeight - parseInt(this.props.user.ury) + this.dottedBorderpx);
        newState.init = true;
      }
      this.setState(newState, this.fixDraggableSize);
    }
  };

  fixCoordsDraggableSize = (xCord, yCord, width, height, pageWidth, pageHeight) => {
    let urx = xCord + width, ury = yCord + height;
    let draggableWidth = width, draggableHeight = height;
    if (urx === 0 || ury === 0 || draggableWidth === 0 || draggableHeight === 0 || pageWidth === 0 || pageHeight === 0 || this.hideImage())
      return null;
    if (pageWidth < draggableWidth) {
      console.warn("Draggable width greater than page width, set to:" + pageWidth);
      return this.fixCoordsDraggableSize(xCord, yCord, pageWidth, height, pageWidth, pageHeight);
    }
    if (pageHeight < draggableHeight) {
      console.warn("Draggable height greater than page height, set to:" + pageHeight);
      return this.fixCoordsDraggableSize(xCord, yCord, width, pageHeight, pageWidth, pageHeight);
    }
    if (xCord - this.dottedBorderpx < 0) {
      console.warn("xcord negative, using 0");
      return this.fixCoordsDraggableSize(0, yCord, width, height, pageWidth, pageHeight);
    }
    if (yCord - this.dottedBorderpx < 0) {
      console.warn("ycord negative, using 0", yCord);
      return this.fixCoordsDraggableSize(xCord, 0, width, height, pageWidth, pageHeight);
    }
    if (urx - this.dottedBorderpx > pageWidth) {
      let newXCord = xCord - (urx - pageWidth - this.dottedBorderpx) - this.dottedBorderpx;
      console.warn("Draggable position greater than page width, set xcord to:" + (newXCord));
      return this.fixCoordsDraggableSize(newXCord, yCord, width, height, pageWidth, pageHeight);
    }
    if (ury - this.dottedBorderpx > pageHeight) {
      let newYCord = yCord - (ury - pageHeight - this.dottedBorderpx) - this.dottedBorderpx;
      console.warn("Draggable position greater than page height, set ycord to:" + (newYCord));
      return this.fixCoordsDraggableSize(xCord, newYCord, width, height, pageWidth, pageHeight);
    }
    return { llx: parseInt(xCord - this.dottedBorderpx), lly: parseInt(pageHeight - ury + this.dottedBorderpx), urx: parseInt(urx - this.dottedBorderpx), ury: parseInt(pageHeight - ury + height + this.dottedBorderpx) };
  }

  fixDraggableSize = () => {
    //let xCord=this.state.xCord,yCord=this.state.yCord,urx=xCord+this.state.width,ury=yCord+this.state.height;
    let xCord = (this.state.xCord), yCord = (this.state.yCord), urx = xCord + this.state.width, ury = yCord + this.state.height;

    let draggableWidth = this.state.width, draggableHeight = this.state.height;
    let pageWidth = this.state.pageWidth, pageHeight = this.state.pageHeight;
    if (urx === 0 || ury === 0 || draggableWidth === 0 || draggableHeight === 0 || pageWidth === 0 || pageHeight === 0 || this.hideImage())
      return;
    if (pageWidth < draggableWidth) {
      console.warn("Draggable width " + draggableWidth + " greater than page width " + pageWidth + ", set to:" + pageWidth);
      this.setState({ width: pageWidth }, this.fixDraggableSize);
      return;
    }
    if (pageHeight < draggableHeight) {
      console.warn("Draggable height " + draggableHeight + " greater than page height , set to:" + pageHeight);
      this.setState({ height: pageHeight }, this.fixDraggableSize);
      return;
    }
    if (xCord - this.dottedBorderpx < 0) {
      console.warn("xcord negative, using 0");
      this.setState({ xCord: this.dottedBorderpx }, this.fixDraggableSize);
      return;
    }
    if (yCord - this.dottedBorderpx < 0) {
      console.warn("ycord negative, using 0", yCord);
      this.setState({ yCord: this.dottedBorderpx }, this.fixDraggableSize);
      return;
    }
    if (urx - this.dottedBorderpx > pageWidth) {
      let newXCord = xCord - (urx - pageWidth - this.dottedBorderpx) - this.dottedBorderpx;
      console.warn("Draggable position greater than page width " + pageWidth + ", set xcord to:" + (newXCord));
      this.setState({ xCord: newXCord }, this.fixDraggableSize);
      return;
    }
    if (ury - this.dottedBorderpx > pageHeight) {
      let newYCord = yCord - (ury - pageHeight - this.dottedBorderpx) - this.dottedBorderpx;
      console.warn("Draggable position greater than page height " + pageHeight + ", set ycord to:" + (newYCord));
      this.setState({ yCord: (newYCord) }, this.fixDraggableSize);
      return;
    }
    const coords = { llx: parseInt(xCord - this.dottedBorderpx), lly: parseInt(pageHeight - ury + this.dottedBorderpx), urx: parseInt(urx - this.dottedBorderpx), ury: parseInt(pageHeight - ury + this.state.height + this.dottedBorderpx) };
    console.log("coords", coords);
    return coords;
  };


  onResize = (event, { size }) => {
    this.setState({ width: size.width, height: size.height });
  };

  onResizeStop = (event, { size }) => {
    this.setState({ width: size.width, height: size.height }, this.fixDraggableSize);
  };

  eventLogger = (e, data) => {
    this.setState({ xCord: data.x, yCord: data.y });
  };

  onDragStop = (e, data) => {
    this.setState({ xCord: data.x, yCord: data.y }, this.fixDraggableSize);
  };

  onPassword = callback => {

    let password = this.props.signatureFiles.entries[this.state.currentFile].password;
    if (password) {
      //this.props.signatureFiles.entries[this.state.currentFile].password=null;
      //password=JSON.parse(password);
      this.setState({ passwords: password }, () => callback(password[this.props.signatureFiles.entries[this.state.currentFile].name]));
      return;
    }

    this.props.modalShow({
      modalType: PDF_PASSWORD_MODAL,
      modalProps: { onCancel: this.cancelPassword, language: this.props.language, footer: null, title: signature[this.props.language].password_for.replace('$0', this.props.signatureFiles.entries[this.state.currentFile].name) },
      componentProps: { language: this.props.language, onSubmit: this.handlePasswordSubmit(callback) }
    });
  };

  cancelPassword = () => {
    this.props.modalDismiss();
    this.props.redirect(ROOT_ROUTE);
  };

  handlePasswordSubmit = callback => {
    return ((formValues) => {
      this.props.modalDismiss();
      let newPasswords = {};
      newPasswords[this.props.signatureFiles.entries[this.state.currentFile].name] = formValues.password;
      this.setState({ passwords: newPasswords }, () => callback(formValues.password));
    }
    )
  };

  showError = (error) => {
    this.props.showMessage('error', error.message);
  };


  renderDocument = () => {
    if (this.props.signatureFiles.entries) {
      return (
        <Document loading={"..."} file={this.props.signatureFiles.entries[this.state.currentFile].content}
          className={'documentSignatureContainer'}
          onLoadError={this.showError}
          onLoadSuccess={this.onDocumentLoadSuccess}
          onPassword={this.onPassword}>
          {this.state.pageNumber ? <Page renderAnnotationLayer={false} loading={"..."}
            onLoadSuccess={this.onPageLoadSuccess} pageNumber={this.state.pageNumber} /> : <></>}
        </Document>
      );
    }
  };

  pageChange = (e) => {
    let page = e.target.value.replace(/\D/g, '');
    page = !page ? page : page > this.state.numPages ? this.state.numPages : page < 1 ? 1 : parseInt(page);
    this.setState({ pageNumber: page });
  }

  pageBlur = () => {
    if (!this.state.pageNumber)
      this.setState({ pageNumber: 1 });
  }

  handleNext = () => {
    this.setState({ pageNumber: this.state.pageNumber + 1 });
  };

  handlePrev = () => {
    this.setState({ pageNumber: this.state.pageNumber - 1 });
  };

  renderNext = () => {
    //if(this.state.pageNumber < this.state.numPages)
    return (
      <Col span={12}>
        <Button disabled={this.state.pageNumber >= this.state.numPages} style={{ width: '100%' }} htmlType={'button'} type={'primary'}
          onClick={this.handleNext}>{general[this.props.language].next}</Button>
      </Col>
    );
  };

  renderPrev = () => {
    //if(this.state.pageNumber > 1)
    return (
      <Col span={12}>
        <Button disabled={this.state.pageNumber <= 1} style={{ width: '100%' }} htmlType={'button'} type={'primary'}
          onClick={this.handlePrev}>{general[this.props.language].prev}</Button>
      </Col>
    );
  };

  getNextIndex = (currentIndex) => {
    const nextIndex = currentIndex + 1;
    if (nextIndex >= this.props.signatureFiles.entries.length)
      return this.getNextIndex(-1);
    if (this.state.processed.includes(nextIndex))
      return this.getNextIndex(nextIndex);
    return nextIndex;
  };

  getPreviousIndex = (currentIndex) => {
    const previousIndex = currentIndex - 1;
    if (previousIndex <= -1)
      return this.getPreviousIndex(this.props.signatureFiles.entries.length);
    if (this.state.processed.includes(previousIndex))
      return this.getPreviousIndex(previousIndex);
    return previousIndex;
  };

  isLastOne = () => {
    if (this.props.signatureFiles.entries)
      return this.state.processed.length + 1 === this.props.signatureFiles.entries.length;
    return true;
  };

  getLastOneCallbacks = (path, logoutAfterSign) => {
    let callbacks = [];
    if (this.isLastOne()) {
      if (logoutAfterSign)
        callbacks = [() => this.props.logoutUser(false, true, false)];
      else
        callbacks = [() => this.props.redirect(path)];
    }
    return callbacks;
  };

  updateCoords = (action = "", rejectComment = null, shareInfo = null) => {
    let callback = null, path = null, logoutAfterSign = false;
    const currentFile = this.state.currentFile, newState = { numPages: null, pageNumber: null }, isLastOne = this.isLastOne();
    if (isLastOne) {
      path = REDIRECT_DOCS_ROUTE + '?active=' + (this.props.user.publish === "true" ? 'public' : 'private');
      logoutAfterSign = this.props.auth.logoutAfter;
    }
    this.props.modalDismiss();
    switch (action) {
      case 'reject':
        newState.currentFile = this.getNextIndex(currentFile);
        newState.processed = [...this.state.processed, currentFile];
        let callbacks = [this.props.decreasePendingFiles, () => this.setState(newState, this.setImageSize)];
        if (isLastOne)
          callbacks = this.getLastOneCallbacks(path, logoutAfterSign);
        this.props.deletePendingFiles(this.props.signatureFiles.entries[this.state.currentFile].uid, rejectComment, [], callbacks);
        return;
      case 'skip':
        newState.currentFile = this.getNextIndex(currentFile);
        if (currentFile !== newState.currentFile)
          this.setState(newState, this.setImageSize);
        return;
      case 'back':
        newState.currentFile = this.getPreviousIndex(currentFile);
        if (currentFile !== newState.currentFile)
          this.setState(newState, this.setImageSize);
        return;
      case 'sign':
        if (!isLastOne) {
          this.resetPad()
          newState.currentFile = this.getNextIndex(currentFile);
          newState.processed = [...this.state.processed, currentFile];
          callback = () => this.setState(newState, this.setImageSize);
        }
        break;
      default:
        console.log("Error, shouldn't happen: " + action);
        break;
    }
    let coords = this.fixDraggableSize();
    if (!coords) {
      if (this.hasSignFields())
        coords = this.getFirstCoordsToLock();
      else
        coords = { llx: 0, lly: 0, urx: 0, ury: 0 };
    }
    if (!coords) {
      if (callback)
        callback();
      if (isLastOne)
        this.getLastOneCallbacks(path, logoutAfterSign)[0]();
      return;
    }
    console.log(coords);
    this.props.updateUserCoords(
      parseInt(coords.llx), parseInt(coords.lly), parseInt(coords.urx), parseInt(coords.ury),
      this.state.pageNumber,
      this.props.signatureFiles.entries[this.state.currentFile].uid,
      this.state.passwords,
      path,
      logoutAfterSign,
      this.state.padImage,
      this.state.padOverwrite,
      shareInfo
    ).then(() => {
      if (callback)
        callback();
    });
  };

  handleSubmit = (closedByPad = false, action = "sign", rejectComment = null, infoShare = null) => {
    if (closedByPad === true && !this.hideImage()) {
      clearInterval(this.state.padTimer);
      this.setState({ padButton: true, padTimer: null });
    }
    this.updateCoords(action, rejectComment, infoShare);
    this.clearImageQR()
  };

  handlePadOverwriteChange = () => {
    this.setState({ padOverwrite: !this.state.padOverwrite });
  };

  hideImage = () => {
    return this.props.user.useImage === '3' && this.props.user.reason === '';
  };

  renderDraggables = () => {
    //if(this.props.signatureFiles.image)
    //{
    if (this.hasSignFields())
      return this.renderSignFieldsDraggable();
    return this.renderDraggable();
    //}
  };

  renderSignFieldsDraggable = () => {
    const newCoords = this.state.newCoords;
    if (newCoords && newCoords.length > 0 && this.props.signatureFiles.entries && this.state.numPages && !this.hideImage()) {
      return newCoords.map((newCoord, index) => {
        if (newCoord && newCoord.dbPage === this.state.pageNumber) {
          const width = parseFloat(newCoord.urx) - parseFloat(newCoord.llx);
          const height = parseFloat(newCoord.ury) - parseFloat(newCoord.lly);
          const xCord = parseFloat(newCoord.llx);
          const yCord = parseFloat(this.state.pageHeight) - parseFloat(newCoord.ury);
          return this.getDraggable(xCord, yCord, width, height, true, index);
        }
        else
          return <div key={index} />
      });
    }
    return <></>;
  };


  getDraggable = (x, y, width, height, disabled, index) => {
    if (width <= 0 || height <= 0)
      return <></>;
    let handle = <div className={'handle handle-enabled'} style={{ width: this.handleSize + 'px', height: this.handleSize + 'px', float: 'left', background: '#60c5ff' }}><DragOutlined style={{ fontSize: 30, color: '#FFF' }} type={'drag'} /></div>;
    const handleLocked = <div className="handle" style={{ width: this.handleSize + 'px', height: this.handleSize + 'px', float: 'left' }} />;
    const handWrite = this.props.user.useImage === '2';
    const lockAspectRatio = (this.props.user.useImage === '4' || this.props.user.useImage === '5') && !handWrite;
    return (
      <Draggable
        key={index}
        axis="both"
        disabled={disabled}
        bounds=".pdfContainer"
        handle={'.handle'}
        defaultPosition={{ x: 0, y: 0 }}
        position={{ x: parseFloat(x), y: parseFloat(y) }}
        scale={1}
        onStart={this.eventLogger}
        onStop={this.onDragStop}>
        <div style={{ display: 'inline-flex', position: 'absolute', zIndex:2 }}>
          {disabled ? handleLocked : handle}
          <Resizable height={height} width={width} onResizeStop={this.onResizeStop}
            onResize={this.onResize} minConstraints={[40, 40]} axis={disabled ? 'none' : 'both'}
            maxConstraints={handWrite ? [300, 200] : disabled ? [1, 1] : this.getMaxConstraints()}
            lockAspectRatio={lockAspectRatio}>
            {handWrite ? this.renderPadImage(width, height) : this.renderSignatureImage(width, height)}
          </Resizable>
        </div>
      </Draggable>
    );
  };

  renderDraggable = () => {
    if (this.props.signatureFiles.entries && this.state.numPages && !this.hideImage())
      return this.getDraggable(this.state.xCord, this.state.yCord, this.state.width, this.state.height, false, 0);
  };

  getMaxConstraints = () => {
    if (this.props.user.useImage === '4' || this.props.user.useImage === '5')
      return [200, 200];
    return [300, 200];
  };

  renderSignatureImage = (width, height) => {
    if (this.props.signatureFiles.image) {
      return (
        <div style={{ width: width, height: height }}>
          <img style={{ width: (width - 4), height: (height - 4) }} className={'handle'} alt={''} src={`data:image/png;base64, ${this.props.signatureFiles.image}`} />
          {/* this.renderUpdateImgSignatureBtn() */}
        </div>
      );
    }
    return <></>;
  }

  renderUpdateImgSignatureBtn = () => {
    /*const useImage=this.props.user?this.props.user.useImage:"3";
    if(useImage === '1')
      return <Button style={{position: 'absolute',borderRadius: 0}} htmlType={'button'} type={'primary'} onClick={this.openUpdateImgSignatureModal}>
        <i className={'netco-icon'}>C</i></Button>;
    else if(useImage === '2')
      return this.renderSignButton();*/
    return <Tooltip title={signature[this.props.language].changeTypeSignature}>
      <Button style={{ position: 'absolute', borderRadius: 0 }} htmlType={'button'} type={'primary'} onClick={this.openChooserModal}>
        <i className={'netco-icon'}>C</i></Button>
    </Tooltip>;
  }

  openUpdateImgSignatureModal = () => {
    this.props.modalShow({
      modalType: SIGNATURE_UPIMAGE_MODAL,
      modalProps: { footer: null, title: profile[this.props.language].signature_modal },
      componentProps: { onSubmit: this.getImageToShow, language: this.props.language, modalDismiss: this.props.modalDismiss }
    });
  };

  openChooserModal = () => {
    this.props.modalShow({
      modalType: SIGNATURE_CHOOSER_MODAL,
      modalProps: { footer: null, title: profile[this.props.language].signature_type },
      componentProps: {
        imageTypes: this.props.user.imageTypes, onChooserChange: this.props.updateUserParam, defaultType: this.props.user.useImage,
        getImageToShow: this.getImageToShow, language: this.props.language, modalDismiss: this.props.modalDismiss,
        onSubmit: this.handlePadImage, padData: this.state.padData, hwimgid: this.props.signatureFiles.hwimgid, setTimerGetImageQR: this.setTimerGetImageQR, clearTimerGetImageQR: () => { clearInterval(this.timerGetImage); this.timerGetImage = null },
        padOverwrite: this.state.padOverwrite, handlePadOverwriteChange: () => this.handlePadOverwriteChange(), onStartPadSigning: this.onStartPadSigning
      }
    });
  };


  renderPadImage = (width, height) => {
    let padHeight = height;
    const reason = this.props.user ? this.props.user.reason : '';
    if (reason !== '')
      padHeight -= (padHeight * 31 / 100);
    return <div style={{ width: width, height: height }}>
      <img style={{ width: (width - 4), height: (padHeight - 4) }} className={'handle'} alt={''} src={`data:image/png;base64, ${this.state.padImage ? this.state.padImage : this.blankImage}`} />
      {reason !== '' ? <img alt={''} style={{ position: 'absolute', bottom: 0, left: 0, width: (width - 4), height: (height - padHeight - 4) }} src={`data:image/png;base64, ${this.props.signatureFiles.image}`} /> : ''}
      {/* this.renderUpdateImgSignatureBtn() */}
    </div>;
  }

  setTimerGetImageQR = () => {
    if (!this.timerGetImage)
      this.timerGetImage = setInterval(() => {
        if (this.props.signatureFiles.hwimgid)
          this.props.getHWImage({ "id": this.props.signatureFiles.hwimgid }, this.props.signatureFiles.infoImageQR.state);
      }, 1000);

  }

  openSignatureModal = () => {
    this.props.modalShow({
      modalType: SIGNATURE_MODAL,
      modalProps: { footer: null, closable: false },
      componentProps: { onSubmit: this.handlePadImage, padData: this.state.padData, hwimgid: this.props.signatureFiles.hwimgid, setTimerGetImageQR: this.setTimerGetImageQR, clearTimerGetImageQR: () => { clearInterval(this.timerGetImage); this.timerGetImage = null } }
    });
  };

  renderSignButton = () => {
    const qrState = this.props.signatureFiles && this.props.signatureFiles.infoImageQR ? this.props.signatureFiles.infoImageQR.state : "";
    if (this.state.padButton)
      return <>{this.renderHandWritingButton(this.onStartPadSigning)}{this.renderSaveAsGraphicCheckBox()}</>;
    else if (this.state.loadingPad)
      return <Spin style={{ position: 'absolute', borderRadius: 0 }} />;
    else if (!this.state.padTimer)
      return <>{qrState !== 'drawing' ? this.renderHandWritingButton(this.openSignatureModal) : <></>}{this.renderSaveAsGraphicCheckBox()}</>;
    else
      return <>{this.renderSaveAsGraphicCheckBox()}</>;
  }

  renderHandWritingButton = (action) => {
    return <Button style={{ position: 'absolute', borderRadius: 0 }} htmlType={'button'} type={'primary'} onClick={action}>
      <i className={'netco-icon'}>C</i></Button>;
  };

  renderSaveAsGraphicCheckBox = () => {
    return <Tooltip title={'Guardar firma manuscrita como firma grafica'}>
      <Checkbox style={{ position: 'absolute', bottom: '-2px', right: '-18px' }} defaultChecked checked={this.state.padOverwrite}
        onChange={this.handlePadOverwriteChange} />
    </Tooltip>;
  };

  getOperationName = () => {
    let operationName = '';
    const operations = this.props.signatureFiles.validOperations, currentOperation = this.props.user.operation;
    if (operations && operations.length > 0 && currentOperation) {
      const selectedOperation = operations.filter((operation) => operation.key === currentOperation + '');
      if (selectedOperation && selectedOperation[0] && selectedOperation[0].value)
        return selectedOperation[0].value;
      return '';
    }
    return operationName;
  };

  zoomInPage = () => {
    const zoomIn = (parseFloat(this.state.zoom) + this.zoomIncrement).toFixed(2)
    if (zoomIn <= this.maxZoom)
      this.setState({ zoom: zoomIn }, this.zoomPage)
  }

  zoomOutPage = () => {
    const zoomOut = (parseFloat(this.state.zoom) - this.zoomIncrement).toFixed(2)
    if (zoomOut > 0)
      this.setState({ zoom: zoomOut }, this.zoomPage)
  }

  handleChangeZoom = (event) => {
    const regex = new RegExp("^[0-9%.]+$");
    if (regex.test(event.target.value)) {
      let percentage = event.target.value.replaceAll('%', '');
      if ((percentage.match(/\./g) || []).length < 2 && percentage !== '.' && percentage <= this.maxZoom) {
        this.setState({ zoom: percentage }, this.zoomPage)
      }
    }

  }

  zoomPage = () => {
    const password = this.state.passwords;
    let pdfPass = null;
    if (password)
      pdfPass = password[this.props.signatureFiles.entries[this.state.currentFile].name];
    this.props.modalShow({
      modalType: ZOOM_MODAL,
      modalProps: { footer: null },
      componentProps: {
        language: this.props.language, entries: this.props.signatureFiles.entries,
        pageNumber: this.state.pageNumber, currentFile: this.state.currentFile, pageWidth: this.state.pageWidth,
        zoom: this.state.zoom, zoomInPage: this.zoomInPage, zoomOutPage: this.zoomOutPage,
        handleChangeZoom: this.handleChangeZoom, pdfPass: pdfPass, numPages: this.state.numPages
      }
    });
  }

  showMessageModal = (entries) => {
    this.props.modalShow({
      modalType: MESSAGE_MODAL,
      modalProps: { footer: null, title: general[this.props.language].comment },
      componentProps: { language: this.props.language, message: entries.optMessage, name: entries.name }
    });
  };

  rejectFiles = () => {
    this.props.modalShow({
      modalType: REJECT_MODAL,
      modalProps: { footer: null, title: general[this.props.language].reject },
      componentProps: {
        language: this.props.language, btn: general[this.props.language].reject, message: general[this.props.language].rejectComment,
        onSubmit: (formValues) => this.handleSubmit(false, 'reject', formValues.comment, null)
      }
    });
  };

  showSignAndShareModal = () => {
    this.props.modalShow({
      modalType: SIGN_AND_SHARE,
      modalProps: { footer: null, title: general[this.props.language].signAndShare },
      componentProps: {
        language: this.props.language, btn: general[this.props.language].btnSignShare, message: general[this.props.language].messageSignShare,
        onSubmit: (formValues) => this.handleSubmit(false, "sign", null, formValues), updateUserSettings: this.props.updateUserSettings
      }
    });
  }

  renderOperations = () => {
    if (this.props.validOperations)
      return (this.props.validOperations.map(item => {
        const key = parseInt(item.key);
        return <Select.Option key={key} value={key}>{item.value}</Select.Option>;
      }
      ));
    return <></>;
  };

  getIconImageType = (type, changeColor = '') => {
    switch (parseInt(type)) {
      case 1:
      default:
        return <ImageOrTextIcon className={changeColor} />
      case 2:
        return <HandwrittenIcon className={changeColor} />
      case 3:
        return <InvisibleIcon className={changeColor} />
      case 4:
        return <QRIcon className={changeColor} />
      case 5:
        return <FingerprintIcon className={changeColor} />
    }
  }

  renderSignaturesType = () => {
    const useImage = this.props.user.useImage;
    const widthCard = this.state.showOptions ? '100%' : '300px';
    const items = this.props.user.imageTypes.map(item => {
      return {
        key: item.id,
        label: <div>
          <Typography>{item.description}</Typography>
          {
            item.id.toString() === useImage.toString() &&
            <Button className='border-button ' onClick={() => this.setState({ showSignatureOptions: true, showFiles: false })} style={{ position: 'absolute', right: 4, top: 8, width: 80, height: 24, padding: 0 }}>
              {general[this.props.language].options}
            </Button>
          }
        </div>,
        icon: this.getIconImageType(item.id)
      }
    })
    return <Card style={{ height: '100%', boxShadow: "4px 0px 4px rgba(0, 0, 0, 0.15)", minWidth: widthCard, maxWidth: widthCard, overflowY: 'auto' }} bodyStyle={{ padding: '30px, 14px, 30px, 30px' }}>
      <Typography className='text-title size18'>{signature[this.props.language].typeSignature}</Typography>
      <Select className='mt-20' defaultValue={this.props.user.operation} onChange={(key) => this.props.updateUserSettings({ operation: key })} style={{ width: 255 }}>
        {this.renderOperations()}
      </Select>
      <div className='card-commom-info mt-15 size10' style={{ borderRadius: 8, padding: '14px 9px 10px 11px', textAlign: 'justify' }}>
        <Typography>{signature[this.props.language].operationDescription[this.props.user.operation]}</Typography>
      </div>
      <Typography className='text-title size18 mt-20'>{signature[this.props.language].typeGraphic}</Typography>
      <Menu theme="dark" items={items} onClick={(type) => { type.key !== useImage && this.props.updateUserSettings({ useImage: parseInt(type.key) }) }}
        mode="vertical"
        defaultSelectedKeys={[useImage]}
        selectedKeys={[useImage]}
        className='mt-10'
      />
    </Card>
  }

  renderLeftOptions = (isMobile) => {
    if (isMobile) {
      return (
        <Drawer bodyStyle={{ backgroundColor: '#FFFFFF' }} open={this.state.showOptions} placement={'left'}
          width={'100%'} closable={true} onClose={() => this.setState({ showOptions: false })}>
          {this.state.showSignatureOptions || this.state.showFiles ? this.renderRightSideOptions() : this.renderSignaturesType()}
        </Drawer>
      );
    } else {
      return this.renderSignaturesType()
    }
  }

  closeOptions = () => {
    this.setState({ showSignatureOptions: false, showFiles: false, showOptions: false })
  }

  renderFiles = (entries) => {
    const items = entries.map((file, index) => { return { key: index, label: file.name } });
    return (
      <Menu theme="dark" items={items} onClick={(evt) => this.setState({ currentFile: parseInt(evt.key) })}
        mode="vertical"
        defaultSelectedKeys={[0]}
        selectedKeys={[`${this.state.currentFile}`]}
        className='mt-10'
      />
    )
  }

  getCurrentSignatures = () => {
    if (this.props.signatureFiles) {
      const entries = this.props.signatureFiles.entries;
      if (entries) {
        const entry = entries[this.state.currentFile];
        if (entry && entry.pdfInfo && entry.pdfInfo.signatureFields)
          return entry.pdfInfo.signatureFields;
      }
    }
    return [];
  };

  renderRightSideOptions = () => {
    const entries = this.props.signatureFiles.entries;
    const currentFile = entries && entries[this.state.currentFile];

    const widthCard = this.state.showOptions ? '100%' : '300px';
    if (this.state.showSignatureOptions || this.state.showFiles)
      return <Card style={{ height: '100%', minWidth: widthCard, maxWidth: widthCard, boxShadow: "-4px 0px 4px rgba(0, 0, 0, 0.15)", overflowY: 'auto' }} bodyStyle={{ overflow: 'auto' }}>
        {!(this.state.showOptions && this.state.showFiles) && <Button className='unstyled-button' style={{ position: 'absolute', top: 6, right: 6 }}
          onClick={this.state.showOptions && this.state.showSignatureOptions ? () => this.setState({ showSignatureOptions: false }) : this.closeOptions} >
          <img src={this.state.showOptions && this.state.showSignatureOptions ? arrowLeftIcon : closeIcon} alt='closeIcon' />
        </Button>}
        {this.state.showSignatureOptions && <NewGraphicChooser imageTypes={this.props.user.imageTypes} onChooserChange={this.props.updateUserParam} defaultType={this.props.user.useImage} closeDrawer={() => this.setState({ showOptions: false })}
          getImageToShow={this.getImageToShow} language={this.props.language} modalDismiss={this.props.modalDismiss} onSubmit={this.handlePadImage} getIconImageType={this.getIconImageType}
          padData={this.state.padData} hwimgid={this.props.signatureFiles.hwimgid} setTimerGetImageQR={this.setTimerGetImageQR} clearTimerGetImageQR={() => { clearInterval(this.timerGetImage); this.timerGetImage = null }}
          padOverwrite={this.state.padOverwrite} handlePadOverwriteChange={() => this.handlePadOverwriteChange()} onStartPadSigning={this.onStartPadSigning} />}
        {this.state.showFiles && this.renderFiles(entries)}
      </Card>;
    if (this.state.showSignatures)
      return <Card style={{ height: '100%', minWidth: widthCard, maxWidth: widthCard, boxShadow: "-4px 0px 4px rgba(0, 0, 0, 0.15)", overflowY: 'auto' }} bodyStyle={{ overflow: 'auto' }}>
        {this.getCurrentSignatures().map((signature) => this.renderSignature(signature))}
      </Card>;
    if (this.state.showInfo)
      return <Card style={{ height: '100%', minWidth: widthCard, maxWidth: widthCard, boxShadow: "-4px 0px 4px rgba(0, 0, 0, 0.15)", overflowY: 'auto' }} bodyStyle={{ overflow: 'auto' }}>
        <div style={{ display: 'flex' }}>
          <img src={infoIcon} alt='infoIcon' />
          <Typography className='text-title size15' style={{ marginLeft: 5 }}>{general[this.props.language].info}</Typography>
        </div>
        {currentFile.name && <Typography className='text-title size13 mt-10'>{currentFile.name}</Typography>}
        <Typography className='text-title size13 mt-10'>{general[this.props.language].properties}</Typography>
        {this.state.moreInfoPDF.creator && <Row className='mt-10'>
          <Col span={8}><Typography className='size12'>{general[this.props.language].creator}</Typography></Col>
          <Col span={16}><Typography className='size12'>{this.state.moreInfoPDF.creator}</Typography></Col>
        </Row>}
        {this.state.moreInfoPDF.lastModified && <Row className='mt-10'>
          <Col span={8}><Typography className='size12'>{signed_files[this.props.language].creationDate}</Typography></Col>
          <Col span={16}><Typography className='size12'>{moment(this.state.moreInfoPDF.lastModified).format('DD/MM/YYYY - HH:mm:ss')}</Typography></Col>
        </Row>}

        {currentFile.content.size && <Row className='mt-10'>
          <Col span={8}><Typography className='size12'>{general[this.props.language].size}</Typography></Col>
          <Col span={16}><Typography className='size12'>{parseInt(parseInt(currentFile.content.size) / 1000) + " KB"}</Typography></Col>
        </Row>}
        <Row className='mt-10'>
          <Col span={8}><Typography className='size12'>{general[this.props.language].type}</Typography></Col>
          <Col span={16}><Typography className='size12'>PDF</Typography></Col>
        </Row>
        {currentFile.optMessage && <Row className='mt-10'>
          <Col span={8}><Typography className='size12'>{general[this.props.language].message}</Typography></Col>
          <Col span={16}><Typography className='size12'>{currentFile.optMessage}</Typography></Col>
        </Row>}
        {currentFile.pdfInfo.pages && <Row className='mt-10'>
          <Col span={8}><Typography className='size12'>{general[this.props.language].pages}</Typography></Col>
          <Col span={16}><Typography className='size12'>{currentFile.pdfInfo.pages}</Typography></Col>
        </Row>}
        {currentFile.uid && <Row className='mt-10'>
          <Col span={8}><Typography className='size12'>UID</Typography></Col>
          <Col span={16}><Typography className='size12'>{currentFile.uid}</Typography></Col>
        </Row>}

        {currentFile.pdfInfo.isSigned && <Typography className='size12 mt-10'>{general[this.props.language].signed}</Typography>}
        {currentFile.pdfInfo.isCertified && <Typography className='size12 mt-10'>{general[this.props.language].certified}</Typography>}

        <Typography className='text-title size13 mt-10'>{general[this.props.language].metadata}</Typography>
        {currentFile.metadata.length > 0 && currentFile.metadata.map(element => {
          if (element.idmetadata)
            return <Row className='mt-10' key={element.idmetadata}>
              <Col span={8}><Typography className='size12'>{this.state.metadataAll.metadata[element.idmetadata]}</Typography></Col>
              {element.value && <Col span={16}><Typography className='size12'>{element.value}</Typography></Col>}
              {element.idmetadataoptions && <Col span={16}><Typography className='size12'>{element.idmetadataoptions}</Typography></Col>}
            </Row>
          return null
        })}
      </Card>;
  }

  selectSignature = (coords) => {
    this.setState({ selectedCoords: coords, pageNumber: coords.dbPage, showSignatures: showFull() }, () => setTimeout(() => this.setState({ selectedCoords: null }), 5000));
  };


  renderSignature = (signature) => {
    const coords = signature.fieldCoords;
    const invisible = coords.llx === '0' && coords.lly === '0' && coords.urx === '0' && coords.ury === '0';
    return <Card style={{ fontSize: '12px' }} key={signature.name}>
      <Typography style={{ fontWeight: 'bold', wordBreak: 'break-word' }}>{signature.name}</Typography>
      {signature.defaultValue !== 'USED' && <Typography>{general[this.props.language].notUsed}</Typography>}
      {signature.signer && <Typography>{general[this.props.language].signerDN}</Typography>}
      {signature.signer && <Typography style={{ wordBreak: 'break-word' }}>{signature.signer}</Typography>}
      {signature.signatureDate !== 0 && <Typography>{general[this.props.language].signatureDate}</Typography>}
      {signature.signatureDate !== 0 && <Typography >{new Date(signature.signatureDate).toLocaleString()}</Typography>}
      {!invisible && <Typography onClick={() => this.selectSignature(coords)} className={'text-link'}>{general[this.props.language].gotoSignature}</Typography>}
      {signature.signerCertificate && <Button onClick={() => handleBase64ZipResponse(signature.signerCertificate, signature.name + '.cer')}>{general[this.props.language].showCert}</Button>}
    </Card>;
  };

  renderSeparator = () => {
    return <div style={{ width: 1, height: 20, border: '1px solid #8F9BB3' }} />
  }

  renderChangeFileButtons = (entries) => {
    if (entries && entries.length > 1)
      return (
        <>
          {this.renderSeparator()}
          <Tooltip title={general[this.props.language].back}>
            <Button style={{ margin: 0, display: 'flex' }} className='unstyled-button'
              htmlType={'button'} onClick={() => this.handleSubmit(false, 'back', '')}>
              <img src={nextFileIcon} alt='nextFileIcon' />
              <Typography className='text-title size12' style={{ alignSelf: 'center', marginLeft: 8 }}>{signature[this.props.language].prevFile}</Typography>
            </Button>
          </Tooltip>
          <Tooltip title={general[this.props.language].selectFile}>
            <Button style={{ margin: 0, display: 'flex' }} className='unstyled-button'
              htmlType={'button'} onClick={() => this.setState({ showFiles: true, showSignatureOptions: false, showOptions: !showFull() })}>
              <img src={browserFileIcon} alt='browserFileIcon' />
            </Button>
          </Tooltip>
          <Tooltip title={general[this.props.language].skip}>
            <Button style={{ margin: 0, display: 'flex' }} className='unstyled-button'
              htmlType={'button'} onClick={() => this.handleSubmit(false, 'skip', '')} >
              <img src={prevFileIcon} alt='prevFileIcon' />
              <Typography className='text-title size12' style={{ alignSelf: 'center', marginLeft: 8 }}>{signature[this.props.language].nextFile}</Typography>
            </Button>
          </Tooltip>
        </>

      )
  }

  renderSelectedSignature = () => {
    const coord = this.state.selectedCoords;
    if (this.myDiv.current && coord) {
      const pageHeight = this.myDiv.current.offsetHeight - 2;
      const xCord = parseFloat(coord.llx);
      const yCord = parseFloat(pageHeight) - parseFloat(coord.ury);
      const width = parseFloat(coord.urx) - parseFloat(coord.llx);
      const height = parseFloat(coord.ury) - parseFloat(coord.lly);
      this.mySelectedDiv.current.scrollIntoView({ top: 0, behavior: 'smooth' });
      return <div className={'blink'} style={{ position: 'absolute', top: yCord - 1, left: xCord - 1, border: '2px dashed', width: width + 2, height: height + 2 }} />;
    }
  }


  render() {
    const entries = this.props.signatureFiles.entries;
    const isMobile = !showFull(), cardPadding = isMobile ? 5 : 70;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Row>
          <Col span={4} style={{ display: 'flex', background: '#E4E9F2', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
          </Col>
          <Col span={16} style={{ display: 'flex', background: '#E4E9F2', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
            <Button type='ghost' className='size12' style={{ marginBottom: 0, height: 24, marginRight: 15 }}
              onClick={() => this.zoomPage()}>
              {general[this.props.language].zoom.toUpperCase()}
            </Button>
            {this.state.pageNumber ? this.renderSeparator() : ''}
            {this.state.pageNumber ? <div><PDFPaginator language={this.props.language} pageNumber={this.state.pageNumber}
              numPages={this.state.numPages} onPageChange={(newPage) => this.setState({ pageNumber: newPage })} /> </div> : ''}
            {this.renderChangeFileButtons(entries)}
            {entries && entries[this.state.currentFile] && entries[this.state.currentFile].optMessage ? this.renderSeparator() : ''}
            {entries && entries[this.state.currentFile] && entries[this.state.currentFile].optMessage ? <Button type='ghost' className='size12' style={{ marginBottom: 0, height: 24, marginLeft: 15 }}
              onClick={() => this.showMessageModal(entries[0])}>
              {general[this.props.language].comment.toUpperCase()}
            </Button> : ''}
          </Col>
          <Col span={4} style={{ display: 'flex', background: '#E4E9F2', justifyContent: 'right', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
            {this.getCurrentSignatures().length > 0 && <Button type='ghost' className='size12' style={{ marginBottom: 0, border: 'transparent', background: 'transparent' }}
              onClick={() => this.setState({ showSignatures: !this.state.showSignatures, showInfo: false })}>
              {/*this.state.showSignatures?general[this.props.language].hideSignatures.toUpperCase():general[this.props.language].showSignatures.toUpperCase()*/}
              <img src={bookOpenIcon} alt='bookOpenIcon' />
            </Button>}
            <Button type='ghost' className='size12' style={{ marginBottom: 0, border: 'transparent', background: 'transparent' }}
              onClick={() => this.setState({ showSignatures: false, showInfo: !this.state.showInfo })}>
              <img src={infoIcon} alt='infoIcon' />
            </Button>
          </Col>
        </Row>

        <div style={{ height: '100%', display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
          {this.renderLeftOptions(isMobile)}
          <div className='mt-10' style={{ width: '100%', overflow: 'auto' }}>
            {entries && entries[this.state.currentFile] && <Row style={{ justifyContent: 'center' }}>
              <Typography className='size12' style={{ marginBottom: 5 }}>{entries[this.state.currentFile].name}</Typography>
            </Row>}
            <Row style={{ justifyContent: 'center' }}>
              <div className={'testPdf'} style={{ position: 'relative', width: this.state.pageWidth }}>
                <div ref={this.myDiv} style={{ position: 'absolute', lineHeight: 0, marginLeft: this.handleSize + 'px', paddingRight: this.handleSize + 'px' }} className={'pdfContainer'}>
                  {this.renderDocument()}
                  <div ref={this.mySelectedDiv}>
                    {this.renderSelectedSignature()}
                  </div>

                </div>
                {this.renderDraggables()}
              </div>
            </Row>
          </div>
          {(!isMobile || (isMobile && this.state.showSignatures) || (isMobile && this.state.showInfo)) && this.renderRightSideOptions()}
        </div>
        <div className='footer-sign1'>
          {!isMobile && <Col>
            <Typography className='size12 mt-20' style={{ paddingLeft: cardPadding }}>{signature[this.props.language].agreeSign}</Typography>
            {renderCopyRight(this.props.language, cardPadding)}
          </Col>}
          <Col className={isMobile && 'mt-20'} style={{ marginLeft: 'auto' }}>
            <Button className='unstyled-button size12 color-black'
              onClick={() => this.props.push(HOME_ROUTE)}>
              {load_files[this.props.language].cancelLabel.toUpperCase()}
            </Button>
          </Col>
          <Col className={isMobile && 'mt-20'} style={{ marginLeft: 20 }}>
            <Button className='border-secondary-button'
              htmlType={'button'} onClick={this.rejectFiles}>{signature[this.props.language].reject.toUpperCase()}</Button>
          </Col>
          <Col className={isMobile && 'mt-20'} style={{ marginRight: '12%', marginLeft: 20 }}>
            {this.state.fingerTimer ? <React.Fragment /> : <SubmitButton onSubmit={this.props.user.dontShowSignAndShare ? this.handleSubmit : this.showSignAndShareModal} type={'primary'}
              label={this.isLastOne() ? signature[this.props.language].sign : signature[this.props.language].sign_next} />}
          </Col>
        </div>
        {isMobile && <Button type={'primary'} className='settingsButton'
          onClick={() => this.setState({ showOptions: true, showFiles: false })}>
          <img src={settingsIcon} alt='settingsIcon' />
        </Button>}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
    language: state.auth.language,
    signatureFiles: state.signatureFiles,
    auth: state.auth,
    location: state.router.location,
    dimApp: state.locate.dimensionsApp,
    validOperations: state.signatureFiles.validOperations,
    metadata: state.reportList.entries,
    params: state.auth.params
  };
};

export default connect(mapStateToProps, {
  getValidOperations, getUserInfo, hideMenu, getUserImageToShow, push, updateUserSettings, getAllMetadata, getPendingFileInformation,
  updateUserCoords, modalShow, modalDismiss, redirect, getPDFPasswordInformation, clearFiles, getHWImage, updateHWImage,
  updateUserParam, setLoadingPad, deletePendingFiles, decreasePendingFiles, logoutUser, showMessage, hideAllMenu
})(SignPreviewer);
