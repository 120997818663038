import React from 'react';
import { Route, Routes } from "react-router-dom";
import Root from './Root';
import RecoverPassword from '../Auth/RecoverPassword';
import RestorePassword from '../Auth/RestorePassword';
import OptCode from "../Auth/OtpCode";
import FingerPrint from "../Auth/FingerPrint";
import QuickSignUp from "../Auth/QuickSignUp/";
import NetcoCodeSignUp from "../Auth/NetcoCodeSignUp/";
import NewManagementDocs from '../NewManagementDocs';
import NewCertificate from "../NewCertificate";
import NetcoPki from "../Certificate/NetcoPki";
import EntrustSm from "../Certificate/EntrustSm";
import EntrustEpf from "../Certificate/EntrustEpf";
import FielMexico from "../Certificate/FielMexico";
import P12 from "../Certificate/P12";
import Zip from "../Certificate/Zip";
import Csr from "../Certificate/Csr";
import Andes from "../Certificate/Andes";
import Directorio from "../Certificate/Directorio";
import NewAdmin from '../NewAdmin';
import NewVerification from "../NewVerification";
import Signature from "../Signature";
import SignPreviewer from "../NewSignature/SignPreviewer";
import TemplateFields from "../Signature/TemplateFields";
import Sqrl from "../Auth/Sqrl";
import NotFound from "../NotFound";
import SignatureModal from '../NewSignature/SignatureModal';
import {
  BASIC_REGISTRATION_ROUTE,
  NETCO_CODES_REGISTRATION_ROUTE,
  NETCO_PKI_REGISTRATION_ROUTE,
  SIGNUP_ROUTE,
  CERTIFICATES_ROUTE,
  NETCO_CODES_CERTIFICATES_ROUTE,
  ENTRUST_CODES_CERTIFICATES_ROUTE,
  ENTRUST_EPF_CERTIFICATES_ROUTE,
  FIEL_CERTIFICATES_ROUTE,
  P12_CERTIFICATES_ROUTE,
  ZIP_CERTIFICATES_ROUTE,
  CSR_CERTIFICATES_ROUTE,
  DIR_CERTIFICATES_ROUTE,
  ADMIN_ROUTE,
  VERIFICATION_ROUTE,
  SIGNATURE_OPTIONS_ROUTE,
  SIGNATURE_SIGN_ROUTE,
  ECOM_ROUTE,
  CHECK_ECOM_ROUTE,
  REDIRECT_DOCS_TABS_ROUTE, LOCATE_ROUTE, ANDES_CERTIFICATES_ROUTE,
  SIGNATUREQR_ROUTE, SEND_AND_SIGN_ROUTE, ACTIVATE_ROUTE, SUBSCRIPTION_ROUTE,
  CHECKWP_ROUTE,
  HOME_ROUTE,
  MANAGEMENT_ROUTE,
  TEST_ROUTE,
  NEW_PENDING_FILES_ROUTE, HOME_NEWCERTIFICATE_ROUTE,
  SYSTEM_STATUS_ROUTE
} from "./routes";
import NewProfile from "../NewProfile"
import {
  FINGERPRINT_ROUTE, ID_REGISTRATION_ROUTE,
  OTP_CODE_ROUTE,
  PROFILE_ROUTE,
  RECOVER_PASSWORD_ROUTE, REDIRECT_DOCS_ROUTE,
  REPORTS_ROUTE,
  RESTORE_PASSWORD_ROUTE, SIGNATURE_FIELDS_ROUTE, SQRL_ROUTE,
  TEMPLATES_ROUTE, WORKFLOW_FILES_ROUTE
} from "./routes";
import Buy from "../Buy";
import RedirectDocs from "../RedirectDocs";
import Locate from "../Locate";
import CheckWordPress from "../CheckWordPress";
import Home from '../Home';
import Shortcuts from '../Layouts/Private/shortcuts';
import NewReport from "../NewReport";
import NewTemplates from "../NewTemplates";
import NewSign from "../NewSendAndSign/NewSign";
//import Sign from "../SendAndSign/Sign"
import NewPendingDocs from '../Docs/NewPendingDocs';
//import NewWorkflowDocs from "../Docs/NewWorkflowDocs";
import NewCertificatePersonal from "../NewCertificatePersonal";
import SystemStatus from '../SystemStatus';
import NewWorkflowDocs from "../Docs/NewWorkflowDocs";

const RoutesContainer = (props) => {
  const renderShortcuts = () =>{
    if(props.showMenus && props.showShortcuts)
      return <Shortcuts />
  }

  // TODO - pasar todo a usar rutas de contantes
  return (
    <div className={'h-100'} style={{ display: 'flex' }}>
      <div style={{ overflowX: 'auto', width:'100%' ,  marginTop:props.marginHeaderBody?40:0 }}>
        <Routes>
          <Route path='/' element={<Root/>} />
          <Route path={HOME_ROUTE} element={<Home/>} />
          <Route path='/state=*id_token=/*' element={<Root/>} />
          <Route path='/id_token=/*' element={<Root/>} />
          <Route path='/access_token=/*' element={<Root/>} />
          <Route path='/_=_' element={<Root/>} />
          <Route path={SIGNUP_ROUTE} element={<QuickSignUp/>} />
          <Route path={BASIC_REGISTRATION_ROUTE} element={<QuickSignUp/>} />
          <Route path={NETCO_PKI_REGISTRATION_ROUTE} element={<QuickSignUp/>} />
          <Route path={NETCO_CODES_REGISTRATION_ROUTE} element={<NetcoCodeSignUp/>} />
          <Route path={ID_REGISTRATION_ROUTE} element={<QuickSignUp/>} />
          <Route path={RECOVER_PASSWORD_ROUTE} element={<RecoverPassword/>} />
          <Route path={RESTORE_PASSWORD_ROUTE} element={<RestorePassword/>} />
          <Route path={OTP_CODE_ROUTE} element={<OptCode/>} />
          <Route path={FINGERPRINT_ROUTE} element={<FingerPrint/>} />
          <Route path={SQRL_ROUTE} element={<Sqrl/>} />
          <Route path={WORKFLOW_FILES_ROUTE} element={<NewWorkflowDocs/>}/>
          <Route path={REDIRECT_DOCS_ROUTE} element={<RedirectDocs/>} />
          <Route path={REDIRECT_DOCS_TABS_ROUTE} element={<RedirectDocs/>} />
          <Route path={CERTIFICATES_ROUTE} element={<NewCertificate/>} />
          <Route path={NETCO_CODES_CERTIFICATES_ROUTE} element={<NetcoPki/>} />
          <Route path={ENTRUST_CODES_CERTIFICATES_ROUTE} element={<EntrustSm/>} />
          <Route path={ENTRUST_EPF_CERTIFICATES_ROUTE} element={<EntrustEpf/>} />
          <Route path={FIEL_CERTIFICATES_ROUTE} element={<FielMexico/>} />
          <Route path={P12_CERTIFICATES_ROUTE} element={<P12/>} />
          <Route path={ZIP_CERTIFICATES_ROUTE} element={<Zip/>} />
          <Route path={CSR_CERTIFICATES_ROUTE} element={<Csr/>} />
          <Route path={ANDES_CERTIFICATES_ROUTE} element={<Andes/>} />
          <Route path={DIR_CERTIFICATES_ROUTE} element={<Directorio/>} />
          <Route path={SIGNATURE_OPTIONS_ROUTE} element={<Signature/>} />
          <Route path={SIGNATURE_SIGN_ROUTE} element={<SignPreviewer/>} />
          <Route path={SIGNATURE_FIELDS_ROUTE} element={<TemplateFields/>} />
          <Route path={VERIFICATION_ROUTE} element={<NewVerification/>} />
          <Route path={REPORTS_ROUTE} element={<NewReport/>} />
          <Route path={TEMPLATES_ROUTE} element={<NewTemplates/>} />
          <Route path={LOCATE_ROUTE} element={<Locate/>} />
          <Route path={ADMIN_ROUTE} element={<NewAdmin/>} />
          <Route path={ECOM_ROUTE} element={<Buy active={'buy'} />} />
          <Route path={CHECK_ECOM_ROUTE} element={<Buy active={'check'} />} />
          <Route path={ACTIVATE_ROUTE} element={<Buy active={'activate'} />} />
          <Route path={SUBSCRIPTION_ROUTE} element={<Buy active={'subscription'} />} />
          <Route path={CHECKWP_ROUTE} element={<CheckWordPress/>} />
          <Route path={PROFILE_ROUTE} element={<NewProfile/>} />
          <Route path={SIGNATUREQR_ROUTE} element={<SignatureModal onSubmit={() => console.log("Continuar Navegador")} modalProps={{ footer: null }} />} />
          <Route path={SEND_AND_SIGN_ROUTE} element={<NewSign/>} />
          <Route path={TEST_ROUTE} element={<NewSign/>} />
          <Route path={SYSTEM_STATUS_ROUTE} element={<SystemStatus/>} />
          <Route path={MANAGEMENT_ROUTE} element={<NewManagementDocs/>} />
          <Route path={NEW_PENDING_FILES_ROUTE} element={<NewPendingDocs/>} />
          <Route path={HOME_NEWCERTIFICATE_ROUTE} element={<NewCertificatePersonal/>} />
          <Route element={<NotFound/>} />
        </Routes>
      </div>
      {renderShortcuts()}
    </div>
  );

}
export default RoutesContainer;