import React from 'react';
import {connect} from 'react-redux'
import checkBasicAuthentication from "../../../actions/auth/checkBasicAuthentication";
import {reduxForm} from "redux-form";
import checkAppCodeAuthentication from "../../../actions/auth/checkAppCodeAuthentication";
import getRegistrationOptions from "../../../actions/auth/getRegistrationOptions";
import {getQueryParams} from "../../Helpers/utils";
import redirect from "../../../actions/auth/redirect";
import {MESSAGE_MODAL} from "../../Feedback/Modal/types";
import modalShow from "../../../actions/modal/modalShow";
import hideMenu from "../../../actions/signature/hideMenu";
import logOutAfter from "../../../actions/auth/logoutAfter";
import admin from "../../../const/admin";
import NewLogInForm from "./NewLogInForm";

class NewLogIn extends React.Component {
    componentDidMount()
    {
        const codeParams=getQueryParams(window.location.search);
        const authParams=getQueryParams(this.props.pathName);
        if((authParams.id_token || authParams.access_token))
        {
            this.props.checkBasicAuthentication({id_token:authParams.id_token,state:authParams.state,access_token:authParams.access_token}).then(
                this.props.getRegistrationOptions
            );
            return;
        }
        else if (codeParams.code)
        {
            let formValues={};
            let state=codeParams.state;
            if(state)
            {
                state=decodeURIComponent(state);
                formValues=getQueryParams(state);
            }
            if(formValues.hideMenu)
                this.props.hideMenu();
            if(formValues.logout)
                this.props.logOutAfter();
            formValues.code=codeParams.code;
            window.history.replaceState(null, "", window.location.pathname);
            this.props.checkBasicAuthentication(formValues).then(this.props.getRegistrationOptions);
            return;
        }
        if(this.props.queryParams.appUser && (this.props.queryParams.appCode || this.props.queryParams.signerCode))
        {
            let form = {}
            if(this.props.queryParams.signerCode)
                form = {
                    userName: this.props.queryParams.appUser,
                    signerCode: this.props.queryParams.signerCode,
                    uniqueID: this.props.queryParams.uniqueID
                }
            else
                form = {
                    userName: this.props.queryParams.appUser,
                    appCode: this.props.queryParams.appCode 
                }
            this.props.checkAppCodeAuthentication(form, this.props.queryParams.uniqueID,!!this.props.queryParams.hideMenu,!!this.props.queryParams.logout,
                this.props.queryParams.usePAD,this.props.queryParams.isNewUser, this.props.queryParams.receptor);
        }else
        {
            if(this.props.queryParams && this.props.queryParams.hideMenu)
                this.props.hideMenu();
            if(this.props.queryParams && this.props.queryParams.logout)
                this.props.logOutAfter();
            this.props.getRegistrationOptions().then(()=>{
                const idpID=sessionStorage.getItem('idp');
                if(idpID && this.props.registration && this.props.registration.idps && this.props.registration.idps.length>0)
                {
                    const currentIDP=this.props.registration.idps.filter((idp)=>JSON.parse(idp).id===idpID)[0];
                    if(currentIDP)
                    {
                        const jsonIDP=JSON.parse(currentIDP);
                        let search='';
                        if(this.props.search)
                            search="&" + this.props.search.substring(1);
                        else if(this.props.invalidSessionPath)
                            search="&redirect=" + this.props.invalidSessionPath;
                        let hint='';
                        if(this.props.returnUserName)
                            hint="&login_hint=" + this.props.returnUserName;
                        else if (codeParams.userName)
                            hint="&login_hint=" + codeParams.userName;
                        setTimeout(()=>this.props.modalShow({
                            modalType: MESSAGE_MODAL,
                            modalProps: {footer: null, title: "...",maskClosable:false,closable:false},
                            componentProps: {language:this.props.language, message:admin[this.props.language].resetSession}}),100);
                        window.location = jsonIDP.url + hint + "&state=" + encodeURIComponent(search + "&state=" + jsonIDP.id);
                        return;
                    }
                }
                if(sessionStorage.getItem("logged") && this.props.search)
                {
                    const params=getQueryParams(this.props.search);
                    this.props.redirect(decodeURIComponent(params.redirect) + this.props.search);
                }
            });
        }
    }

    logIn = (formValues) => {
        formValues.redirect=this.props.queryParams.redirect;
        formValues.uniqueID=this.props.queryParams.uniqueID;
        formValues.selectedTemplate=this.props.queryParams.selectedTemplate;
        formValues.signFirst=this.props.queryParams.signFirst;
        formValues.signAll=this.props.queryParams.signAll;
        formValues.usePAD=this.props.queryParams.usePAD;
        formValues.defaultOperation=this.props.queryParams.defaultOperation;
        formValues.referenceNumber=this.props.queryParams.referenceNumber;
        formValues.option=this.props.queryParams.option;
        formValues.authCode=this.props.queryParams.authCode;
        formValues.token=this.props.queryParams.token;
        formValues.x_id_factura=this.props.queryParams.x_id_factura;
        this.props.checkBasicAuthentication(formValues);
    };

    render()
    {
        if(this.props.hasSession)
            return <></>;
        return <NewLogInForm initialValues={this.props.initialValues} onSubmit={this.logIn} {...this.props} />;
    }
}

const mapStateToProps = state => {
    let params = state.router.location.search;
    if(params)
        params=decodeURIComponent(params);
    let queryParams = getQueryParams(params);
    let queryUserName=queryParams.userName;
    return {
        queryParams: queryParams,
        invalidSessionPath:state.auth.invalidSessionPath,
        returnUserName:state.auth.userName,
        pathName:state.router.location.pathname,
        search:state.router.location.search,
        registration: state.registration,
        language: state.auth.language,
        hasSession:state.session.hasSession,
        initialValues: {
            userName: queryUserName
        },
        location:state.router.location,
        customization: state.adminConfiguration.customization
    };
};

export default connect(mapStateToProps, {checkAppCodeAuthentication, checkBasicAuthentication,getRegistrationOptions,
    redirect,modalShow,hideMenu,logOutAfter})((reduxForm({
    form: 'login_form'
})(NewLogIn)));
