
const signature = {
  es: {
    title: 'Firmar',
    info: 'Seleccione la operación que desea realizar y cargue los documentos a firmar',
    sign_info: 'Mueva la firma a donde la necesita',
    edit_options: 'Configura tu firma',
    pending_files: 'Archivos pendientes',
    operation_step: 'Operacion',
    options_step: 'Ubicación de los documentos',
    selection_step: 'Selección',
    operation: 'Operacion',
    pre_pdf: 'Pre visualizar PDF',
    send_mail: 'Enviar correo',
    email: 'Copiar archivos firmados al correo',
    template: 'Usa un formulario que tengas en tu librería de plantillas',
    saveOption: 'Guardar las opciones seleccionadas',
    load_files: 'Selecciona y carga el y/o los archivos que deseas firmar ',
    select_files: 'Máximo ',
    pre_template: 'Pre visualizar plantilla seleccionada',
    select_template: 'Seleccione una plantilla:',
    end: 'Ir a firmar',
    sign_next: 'Firmar e ir al siguiente',
    sign: 'Firmar',
    select_option: 'Seleccione una opción',
    password_for: 'Contraseña para $0',
    step1message: 'Seleccione la operación que va a realizar',
    step2message: 'Seleccione la ubicación de los documentos a firmar',
    step3message: 'Cargue los archivos que requiera firmar',
    templateField_title: 'Plantilla',
    templateField_message: 'Se encontraron los siguientes campos en el archivo, por favor complételos para proceder a firmar',
    generate: 'Continuar',
    flat: 'Remover campos PDF',
    modalTitle: 'Dibuje su firma dentro del recuadro punteado',
    hideQR: 'Ocultar código QR',
    useQR: 'Usa tu celular o tablet',
    clear: 'Limpiar',
    accept: 'Actualizar firma',
    cancel: 'Cancelar',
    showPreferences: 'Ver preferencias',
    agreeSign: 'Al firmar estas aceptando Términos y condiciones.',
    reject: 'Rechazar',
    prevFile: 'Anterior',
    nextFile: 'Siguiente',
    typeGraphic: 'Tipo de gráfica',
    typeSignature: 'Tipo de firma',
    copyQR: 'URL copiada al portapapeles',
    errCopyQR: 'Error al copiar la URL',
    copyShareQR: 'Copia y comparte el link del QR',
    operationDescription:[
      '',
      'Con esta opción sólo insertas al documento PDF el componente gráfico de tu firma.',
      'En esta opción utilizas el sello de tiempo que tienes configurado en tu perfil.',
      'En esta opción utilizas tu certificado digital y adicionalmente incrustas el sello de tiempo que tienes configurado en tu perfil.',
      'En esta opción solamente incrustas la parte grafica de tu firma al documento PDF.'
    ],
    signatureTitleTypeID1:[
      '',
      'Imagen o texto',
      'Manuscrita',
      'Sin firma gráfica',
      'Código QR',
      'Huella dactilar.'
    ],
    signatureTitleTypeID:[
      '',
      'Imagen o texto',
      'Manuscrita',
      'Firma sin componente gráfico',
      'Utiliza este QR como tu firma gráfica',
      'Firma con tu huella dactilar.'
    ],
    signatureTextTypeID:[
      '','',
      {instructions:[
        '1. Usa la cámara de tu celular para escanear este código QR.', 
        '2. Cuando ingreses gira horizontalmente tu celular, verifica que tengas la opción de rotación activada.',
        '3.  Dibuja tu firma en el dispositivo móvil.']},
      'Cuando utilizas firma digital el sistema usa el certificado que tienes configurado en tu cuenta de Netco Signer. Al momento de revisar el estado de la firma, lo que más importa es el certificado que se utilizó para el proceso de firma, la parte gráfica no es tan relevante y puedes omitirla. Al seleccionar este tipo de firma, desaparecerá la parte gráfica de la firma y el sistema solo usará el certificado digital para firmar el documento.',
      'Si utilizas este código QR como parte gráfica de la firma, la persona que verifique el estado de tu firma podrá escanearlo y obtener más información de la firma (Dependerá de las configuraciones de tu aplicación).',
      'Puedes utilizar tu huella para la parte gráfica de tu firma, solo debes configurar un lector de huella digital en Netco Signer.'
    ],
    handwrittenSignatureMobile:'Firma manuscrita usando dispositivo móvil',
    handwrittenSignaturePad:'Firma manuscrita usando pad',
    messageUseSignatureMobile:'Estás haciendo uso de tu dispositivo móvil para firmar el documento, si necesitas habilitar las demás opciones de firma manuscrita, debes presionar el botón cancelar en tu dispositivo',
    messageUseSignaturePad: 'Estás haciendo uso de tu pad para firmar el documento, si necesitas habilitar las demás opciones de firma manuscrita, debes presionar el botón cancelar en tu dispositivo',
    scanQR:'Escanea este código QR',
    disabledPad:'No se puede establecer conexión con el pad de firma',
    enabledPad:'Puedes realizar los gestos de tu firma desde el pad',
    handwrittenSignatureOptions:[
      'Dibuja la firma desde tu móvil', 'Dibuja la firma desde tu pad', 'Dibuja la firma en el recuadro'
    ],
    saveHandwrittenSignature:'Marca esta opción para guardar los gestos de tu firma manuscrita como parte gráfica',
    saveHandwrittenSignatureEnable:'Presiona para guardar los gestos de tu firma manuscrita como parte gráfica',
    saveHandwrittenSignatureDisable:'Estás guardando tu firma manuscrita como parte gráfica, presiona para deshabilitar esta opción',
    agree:'Cuando presiones firmar aceptarás términos y condiciones. ',
    changeTypeSignature:'Cambia tu tipo de firma.',
    rescan:'Vuelve a escanear el código QR',
    continueWeb:'Puedes continuar desde la aplicación web'
  },
  en: {
    title: 'Sign',
    info: 'Choose the options for signature',
    sign_info: 'Place the signature where you need it',
    edit_options: 'Edit signature options',
    pending_files: 'Pending files',
    operation_step: 'Operation',
    options_step: 'Options',
    selection_step: 'Selection',
    operation: 'Operation',
    pre_pdf: 'Preview PDF',
    send_mail: 'Send email',
    email: 'Copy signed files to mail',
    template: 'Use PDF template',
    saveOption: 'Save checked options',
    load_files: 'Load file(s)',
    select_files: 'Select file(s)',
    pre_template: 'Preview selected template',
    select_template: 'Select a template:',
    end: 'Finish',
    sign_next: 'Sign and see next',
    sign: 'Sign',
    select_option: 'Select an option',
    password_for: 'Password for $0',
    step1message: 'Select the desired operation',
    step2message: 'Select your signature options',
    step3message: 'Upload the files you with to sign',
    templateField_title: 'Template',
    templateField_message: 'Complete the following fields',
    generate: 'Continue',
	  flat: 'PDF form flattening',
    modalTitle: 'Draw your signature inside the dotted rectangle',
    hideQR: 'Hide QR code',
    useQR: 'Use your cell phone or tablet',
    clear: 'Clean',
    accept: 'Update Signature',
    cancel: 'Cancelar',
    showPreferences: 'View preferences',
    agreeSign: 'By signing you are agreeing to Terms and Conditions.',
    reject: 'Reject',
    prevFile: 'Previous',
    nextFile: 'Next',
    typeGraphic: 'Graphic type',
    typeSignature: 'Type of signature',
    copyQR: 'URL copied to clipboard',
    errCopyQR: 'Error copying URL',
    copyShareQR: 'Copy and share QR link',
    operationDescription:[
      '',
      'In this option you use your digital certificate to sign the documents.',
      'In this option you use the time stamp that you have configured in your profile.',
      'In this option you use your digital certificate and additionally embed the time stamp that you have configured in your profile.',
      'In this option you only embed the graphic part of your signature to the PDF document.'
    ],
    signatureTitleTypeID1:[
      '',
      'Image or text',
      'Handwritten',
      'No graphic signature',
      'QR code',
      'Fingerprint.'
    ],
    signatureTitleTypeID:[
      '',
      'Graphic signature',
      'Handwritten signature',
      'None',
      'Graphic signature with QR',
      'Signature with Fingerprint'
    ],
    signatureTextTypeID:[
      '','',
      {instructions:[
        '1. Use your phones camera to scan this QR code.', 
        '2. When you enter rotate your cell phone horizontally, verify that you have the rotation option activated.',
        '3. Draw your signature on the mobile device.']},
      'When you use a digital signature, the system uses the certificate that you have configured in your Netco Signer account. When checking the status of the signature, what matters most is the certificate that was used for the signing process, the graphic part is not that relevant and you can omit it. By selecting this type of signature, the graphic part of the signature will disappear and the system will only use the digital certificate to sign the document.',
      'If you use this QR code as a graphic part of the signature, the person who verifies the status of your signature will be able to scan it and obtain more information about the signature (It will depend on your application settings).',
      'You can use your fingerprint as a graphic part of your signature, you just have to configure a digital fingerprint reader in Netco Signer.'
    ],
    handwrittenSignatureMobile:'Handwritten signature using mobile',
    handwrittenSignaturePad:'Handwritten signature using pad',
    messageUseSignatureMobile:'You are using your mobile device to sign the document, if you need to enable the other handwritten signature options, you must press the cancel button on your device',
    messageUseSignaturePad:'You are using your pad to sign the document, if you need to enable the other handwritten signature options, you must press the cancel button on your device',
    scanQR:'Scan this QR code',
    disabledPad:'Cannot connect to signature pad',
    enabledPad:'You can perform your signature gestures from the pad',
    handwrittenSignatureOptions:[
      'Sign from your mobile', 'Sign from your pad', 'You can make your signature here'
    ],
    saveHandwrittenSignature:'You can save the gestures of your handwritten signature as a graphic part of the signature.',
    saveHandwrittenSignatureEnable:'Press to save the gestures of your handwritten signature as a graphic part',
    saveHandwrittenSignatureDisable:'You are saving your handwritten signature as a graphic part, press to disable this option',
    agree:'By pressing sign, you will be accepting terms and conditions',
    changeTypeSignature:'Change your signature type.',
    rescan:'Rescan the QR code',
    continueWeb:'You can continue from the web application'
  }
};
export default signature;
