import React from 'react';
import PrivateRoute from "../../Root/PrivateRoute";
import { connect } from "react-redux";
import { Button, Card, Checkbox, Col, Collapse, Input, Row, Select, Switch, Typography, Tooltip, AutoComplete } from "antd";
import getAllUsers from "../../../actions/report/getAllUsers";
import getUserInfo from "../../../actions/profile/getUserInfo";
import SubmitButton from "../../Helpers/SubmitButton";
import load_files from "../../../const/load_files";
import getPDFTemplatesInformation from "../../../actions/templates/getPDFTemplatesInformation";
import putFiles from "../../../actions/sendFiles/putFiles";
import getWorkflowOptions from "../../../actions/files/getWorkflowOptions";
import saveWorkflowOption from "../../../actions/files/saveWorkflowOption";
import deleteWorkflowOption from "../../../actions/files/deleteWorkflowOption";
import hideAllMenu from "../../../actions/info/hideAllMenu"
import { push } from "connected-react-router";
import { HOME_ROUTE } from "../../Root/routes";
import collapseUpIcon from '../../../resources/img/NewIcons/sign/collapseUpIcon.svg';
import collapseDownIcon from '../../../resources/img/NewIcons/sign/collapseDownIcon.svg';
import infoIcon from '../../../resources/img/NewIcons/sign/infoIcon.svg';
import keypad from '../../../resources/img/NewIcons/sign/keypad.svg';
import trashIcon from '../../../resources/img/NewIcons/trashIcon.svg';
import { addMetadata, getCustomColors, getQueryParams, renderCopyRight, sGrid } from "../../Helpers/utils";
import signature from "../../../const/signature";
import SignDropZoneField from "../../Helpers/SignDropzoneField";
import profile from "../../../const/profile";
import { CheckOutlined } from "@ant-design/icons";
import general from "../../../const/general";
import moment from "moment";
import { NEW_PREFERENCE_MODAL, NEW_VIEW_PREFERENCE_MODAL, TEMPLATES_MODAL } from "../../Feedback/Modal/types";
import modalShow from "../../../actions/modal/modalShow";
import modalDismiss from "../../../actions/modal/modalDismiss";
import templates from "../../../const/templates";
import { INFO_SHOW } from "../../../actions/types";
import { emaillang } from "../../Helpers/Input/validations";
import { loadFileInitialValues } from "../../../apis/initialValues";
import { Table } from 'antd';
import DraggableBodyRow from '../../NewTemplates/DraggableRow';
import '../NewSend.css';
import getPDFTemplate from '../../../actions/templates/getPDFTemplate';
import { loadFileFieldsToHide } from '../../../apis/hideFields';
import getAllMetadata from "../../../actions/admin/getAllMetadata";
import { ReactComponent as LockIcon } from '../../../resources/img/NewIcons/sign/lockIcon.svg';
import { ReactComponent as UnlockIcon } from '../../../resources/img/NewIcons/sign/unlockIcon.svg';
import { ReactComponent as ShoppingBagIcon } from '../../../resources/img/NewIcons/sign/shoppingBagIcon.svg';


const { TextArea } = Input;

class NewSign extends React.Component {
    state = {
        formFile: [], signers: [''], templates: [], tempOptions: [], searchResults: [],
        locate: loadFileInitialValues.locate,
        ordered: loadFileInitialValues.ordered,
        multipleWorkflow: loadFileInitialValues.multipleWorkflow,
        maxDate: loadFileInitialValues.maxDate,
        remind: loadFileInitialValues.remind,
        remindEvery: loadFileInitialValues.remindEvery,
        saveOption: loadFileInitialValues.saveOption,
        optMessage: loadFileInitialValues.optMessage,
        copyToCheck: loadFileInitialValues.copyToCheck,
        combinedFileName: loadFileInitialValues.combinedFileName,
        combinePDF: loadFileInitialValues.combinePDF,
        sendConfirmation: loadFileInitialValues.sendConfirmation,
        sendAll: loadFileInitialValues.sendAll,
        onlySigner: loadFileInitialValues.whoSigns === 0,
        optionName: loadFileInitialValues.optionName,
        userSettings: []
    };
    defaultOption = {
        signers: [''], templates: [],
        optionName: loadFileInitialValues.optionName,
        optMessage: loadFileInitialValues.optMessage,
        combinedFileName: loadFileInitialValues.combinedFileName,
        combinePDF: loadFileInitialValues.combinePDF,
        locate: loadFileInitialValues.locate,
        remindEvery: loadFileInitialValues.remindEvery,
        sendConfirmation: loadFileInitialValues.sendConfirmation,
        sendAll: loadFileInitialValues.sendAll,
        multipleWorkflow: loadFileInitialValues.multipleWorkflow,
        onlySigner: loadFileInitialValues.whoSigns === 0,
        ordered: loadFileInitialValues.ordered,
        maxDate: loadFileInitialValues.maxDate,
        saveOption: loadFileInitialValues.saveOption,
    };

    componentDidMount() {
        this.props.getUserInfo();
        this.props.getAllMetadata();
        this.props.hideAllMenu(true);
        this.addSigner();
        this.signersToTable();
        this.props.getWorkflowOptions();
        this.props.getAllUsers();
        if (this.props.templateID && this.props.templateName)
            this.selectedTemplates([{ id: this.props.templateID, name: decodeURIComponent(this.props.templateName) }]);
    }

    componentWillUnmount() {
        this.props.hideAllMenu(false);
    }

    submit = () => {
        const formValues = this.createFormValues();
        if (formValues) {
            if (this.state.saveOption) {
                const saveValues = { ...formValues };
                if (formValues.templateIds)
                    saveValues.templateIds = '[' + formValues.templateIds.map((id) => JSON.stringify(id)).join(',') + ']';
                this.props.saveWorkflowOption(saveValues, () => this.setState({ optionName: formValues.optionName }, () => this.sendAndSign(formValues)));
            }
            else
                this.sendAndSign(formValues);
        }
    };

    sendAndSign = (formValues) => {
        this.props.putFiles(formValues, this.state.formFile, '', this.shouldSign());
    };

    createFormValues = () => {
        const formValues = {};
        if (this.state.templates && this.state.templates.length > 0) {
            formValues.templateIds = this.state.templates.map(template => { return { id: template.id, metadata: template.metadata } });
            formValues.withTemplate = true;
        } else if (!this.state.formFile || this.state.formFile.length === 0) {
            this.props.dispatch({ type: 'INFO_SHOW', payload: { info: { message: templates[this.props.language].fileRequired, type: 'error' } } });
            return;
        }
        if (this.state.remind && !this.state.maxDate) {
            this.props.dispatch({ type: INFO_SHOW, payload: { info: { message: load_files[this.props.language].requiredDate, type: 'error' } } });
            return;
        }
        if (this.state.copyTo) {
            formValues.copyTo = this.state.copyTo.replace(/ /g, "")
            let mails = formValues.copyTo.split(',');
            let invalidMails = 0;
            let msg = load_files[this.props.language].invalidMails;
            mails.forEach(value => emaillang(msg)(value) ? invalidMails++ : '');
            if (invalidMails > 0) {
                this.props.dispatch({ type: INFO_SHOW, payload: { info: { message: msg, type: 'error' } } });
                return
            }
        }
        if (this.state.saveOption && (!this.state.optionName || this.state.optionName.trim() === '')) {
            this.props.dispatch({ type: INFO_SHOW, payload: { info: { message: load_files[this.props.language].optionNameRequired, type: 'error' } } });
            return;
        }
        if (this.state.saveOption && this.state.optionName.trim() === this.defaultOption.optionName) {
            this.props.dispatch({ type: INFO_SHOW, payload: { info: { message: load_files[this.props.language].optionEqualsDefaultError, type: 'error' } } });
            return;
        }
        if (this.state.combinedFileName && this.state.combinePDF) {
            formValues.combinedFileName = this.state.combinedFileName;
            if (formValues.combinedFileName.includes('"'))
                formValues.combinedFileName = formValues.combinedFileName.replace(/"/g, "");
        }

        formValues.remind = this.state.remind;
        if (formValues.remind)
            formValues.maxDate = this.state.maxDate;
        formValues.combinePDF = this.state.combinePDF;
        if (this.state.remind)
            formValues.remindEvery = this.state.remindEvery;
        formValues.sendConfirmation = this.state.sendConfirmation;
        formValues.ordered = this.state.ordered && !this.state.onlySigner;
        formValues.locate = this.state.locate;
        formValues.sendAll = this.state.sendAll;
        formValues.multipleWorkflow = this.state.multipleWorkflow;
        if (this.state.signers.length > 0) {
            formValues.usersToPut = this.state.signers;
            if (this.state.onlySigner) {
                formValues.userToPut = this.props.user.userName;
                formValues.usersToPut = null;
            }
            else if (this.hasEmptySigners()) {
                this.props.dispatch({ type: 'INFO_SHOW', payload: { info: { message: load_files[this.props.language].signersDescription, type: 'error' } } });
                return false;
            }
        }
        formValues.optionName = this.state.optionName;
        formValues.whoSigns = this.state.onlySigner ? 0 : 1;
        if (this.state.optMessage)
            formValues.optMessage = this.state.optMessage;
        formValues.signerFileSettings = {}
        let numTransfer = 0;
        this.state.signers.forEach((signer, index) => {
            if (signer !== '') {
                formValues.signerFileSettings[signer] = {
                    "appCode": this.state.userSettings[index]?.getAppCode ?? false,
                    "transferOperations": (this.state.userSettings[index]?.transferOperations ?? this.props.user.defaultTransfer) && this.redertransferUser(signer) 
                }
                if (formValues.signerFileSettings[signer].transferOperations)
                    numTransfer++;
            }
        })
        const numFiles=this.state.templates.length + this.state.formFile.length;
        if(numTransfer === 0)
            formValues.numTransfer = false
        else{
            formValues.numTransfer = formValues.locate?0:numTransfer*numFiles;
        }
        return formValues;
    };

    shouldSign = () => {
        const signers = this.state.signers, userName = this.props.user.userName, ordered = this.state.ordered;
        return this.state.onlySigner || signers.length === 0 || (signers.includes(userName) && (!ordered || (ordered && signers[0] === userName)));
    };

    renderPanelHeader = (title, description) => {
        return <Row>
            <Col span={24}>
                <Typography className={'text-title size32'}>{title}</Typography>
            </Col>
            <Col span={24}>
                <Typography className={'size15'}>{description}</Typography>
            </Col>
        </Row>;
    };

    addFormFiles = (files) => {
        this.setState({ formFile: [...this.state.formFile, ...files] });
    };

    handleOnDrop = (files) => {
        addMetadata(files, this.addFormFiles, this.props.metadata, this.props.modalShow, this.props.language, this.props.modalDismiss, this.props.dispatch);
    }

    handleDeleteFile = (value) => {
        this.setState({ formFile: this.state.formFile.filter(item => item.name !== value) });
    };

    updateRemindMaxDate = (remind, days = 1) => {
        this.setState({ remind, maxDate: remind ? moment().add(days, 'day').startOf('day') : null });
    };

    createReminderOptions = () => {
        const options = [];
        for (let i = 1; i < 121; i++)
            options.push(<Select.Option key={i} value={i}>{i}</Select.Option>);
        return options;
    };

    changeMultipleWorkflow = (e) => {
        const newValue = e.target.checked, newState = { multipleWorkflow: newValue };
        if (newValue)
            newState.ordered = false;
        this.setState(newState);
    };

    hasEmptySigners = () => {
        for (let i = 0; i < this.state.signers.length; i++) {
            if (this.state.signers[i].trim() === '')
                return true;
        }
        return false;
    }

    addSigner = () => {
        if (!this.hasEmptySigners())
            this.setState({ signers: [...this.state.signers, ''] });
    };

    updateSigner = (e, index) => {
        const newState = this.state.signers.map((signer, signerIndex) => {
            if (index === signerIndex)
                return e;
            return signer;
        });
        this.setState({ signers: newState });
    };

    deleteSigner = (userName) => {
        this.setState({ signers: this.state.signers.filter(item => item !== userName) }, () => {
            this.state.signers.length === 0 && this.addSigner();
        });
    }

    signersToTable = () => {
        return this.state.signers.map((signer, index) => {
            return { key: index, signer: signer }
        });
    }

    moveRow = (dragIndex, hoverIndex) => {
        const newSigners = [...this.state.signers]
        newSigners[hoverIndex] = newSigners[dragIndex]
        newSigners[dragIndex] = this.state.signers[hoverIndex]

        const newSettings = [...this.state.userSettings]
        newSettings[hoverIndex] = newSettings[dragIndex]
        newSettings[dragIndex] = this.state.userSettings[hoverIndex]
        this.setState({ signers: newSigners, userSettings: newSettings }, () => { console.log(newSigners, newSettings) })
    };

    columns(isMobile) {
        return (
            [{
                dataIndex: 'users',
                className: 'mobile-show',
                key: 'key',
                render: (text, record) => {
                    return this.renderSigner(record.signer, record.key, isMobile);
                }
            }]
        );
    }

    updateWorkflowOptions = (currentOption, isTemp = false) => {
        if (!isTemp) {
            let newOption = this.props.options.filter((option) => option.optionName === currentOption)[0];
            let isDefault = false
            if (!newOption) {
                newOption = this.defaultOption;
                isDefault = true;
            }
            if (newOption.signers) {
                if (!Array.isArray(newOption.signers))
                    newOption.signers = newOption.signers.split(',');
            }
            else
                newOption.signers = [''];
            newOption.combinePDF = !!newOption.combinedFileName && !isDefault;
            newOption.copyToCheck = !!newOption.copyTo;
            newOption.saveOption = !isDefault
            newOption.onlySigner = newOption.whoSigns === 0;
            newOption.remind = !!newOption.remindEvery;
            newOption.remindEvery = newOption.remindEvery || loadFileInitialValues.remindEvery;
            if (newOption.templateIds) {
                let newIDS, metadata = [];
                if (this.isJsonString(newOption.templateIds)) {
                    const jsonIds = JSON.parse(newOption.templateIds);
                    newIDS = jsonIds.map((id) => {
                        if (id.metadata)
                            metadata[id.id] = id.metadata;
                        return id.id;
                    });
                }
                else {
                    const ids = Array.isArray(newOption.templateIds) ? newOption.templateIds : newOption.templateIds.split(',');
                    newIDS = ids.map((id) => this.isJsonString(id) ? JSON.parse(id).id : id);
                }
                this.props.getPDFTemplate(newIDS, false, (data) => {
                    data.forEach((dat) => dat.metadata = metadata[dat.id])
                    newOption.templates = data
                    this.setState({ ...newOption });
                })
            }
            else {
                newOption.templates = [];
                this.setState({ ...newOption });
            }
        }
        this.props.modalDismiss()
    };

    isJsonString = (item) => {
        let value = typeof item !== "string" ? JSON.stringify(item) : item;
        try {
            value = JSON.parse(value);
        } catch (e) {
            return false;
        }

        return typeof value === "object" && value !== null;
    };

    cancelPreference = () => {
        this.setState({ saveOption: false }, this.props.modalDismiss);
    };

    saveOption = (optionName) => {
        if (optionName)
            this.setState({ optionName }, this.props.modalDismiss)
        else
            this.props.dispatch({ type: INFO_SHOW, payload: { info: { message: "Debe ingresar un nombre para guardar las preferencias.", type: 'error' } } });
    }

    showsaveOption = (e) => {
        const checked = e.target.checked;
        this.setState({ saveOption: checked }, () => {
            if (checked)
                this.props.modalShow({
                    modalType: NEW_PREFERENCE_MODAL,
                    modalProps: { footer: null, title: load_files[this.props.language].newPreferenceTitle, onCancel: this.cancelPreference },
                    componentProps: { language: this.props.language, onSave: this.saveOption }
                });
            else {
                this.saveOption(this.defaultOption.optionName)
                this.props.modalDismiss();
            }
        });
    }

    deleteWorkflowOption = (optionName, optionSave = false) => {
        if (optionSave) {
            this.updateWorkflowOptions(this.defaultOption.optionName)
        }
        const optionToDelete = this.props.options.filter((option) => option.optionName === optionName)[0];
        optionToDelete && this.props.deleteWorkflowOption({ optionName }, () => this.props.getWorkflowOptions());
        this.props.modalDismiss()
    }

    showPreferenceModal = () => {
        const optionSave = this.state.optionName && this.state.optionName !== this.defaultOption.optionName ? [{ optionName: this.state.optionName, temp: true }] : []
        const optionsTable = [...[this.defaultOption], ...this.props.options.filter(item => item.optionName !== this.state.optionName), ...optionSave];
        this.props.modalShow({
            modalType: NEW_VIEW_PREFERENCE_MODAL,
            modalProps: { footer: null, title: load_files[this.props.language].newPreferencesView, onCancel: this.props.modalDismiss },
            componentProps: { language: this.props.language, updatePreference: this.updateWorkflowOptions, optionsTable: optionsTable, deletePreference: this.deleteWorkflowOption }
        });
    }

    selectedTemplates = (newTemplates) => {
        addMetadata(newTemplates, () => {
            const templates = [...this.state.templates, ...newTemplates]
            this.setState({ templates }, this.props.modalDismiss)
        }, this.props.metadata, this.props.modalShow, this.props.language, this.props.modalDismiss, this.props.dispatch);
    }

    handleDeleteTemplate = (value) => {
        const filterTemplates = this.state.templates.filter(item => item.id !== value)
        this.setState({ templates: filterTemplates, withTemplate: filterTemplates.length > 0 });
    };

    showTemplatesModal = () => {
        this.props.modalShow({
            modalType: TEMPLATES_MODAL,
            modalProps: { footer: null, title: load_files[this.props.language].newPreferenceTitle, onCancel: this.props.modalDismiss, selectedTemplates: this.selectedTemplates },
            componentProps: { language: this.props.language, onSave: this.saveOption }
        });
    }

    searchValue = (value) => {
        this.setState({
            searchResults: value && value.length > 0 ? this.props.users.filter(item => {
                return item.value.includes(value) && !this.state.signers.includes(item.value)
            }) : []
        });
    };

    formatUserName = (e, index) => {
        const userName = e.replace(' ', '').toLowerCase().trim();
        if (userName.length <= 50)
            this.updateSigner(userName, index);
    }

    handleUserSettings = (index, value, setting) => {
        this.setState((prevState) => {
            const updatedUserSettings = [...prevState.userSettings];
            const currentUserSettings = { ...updatedUserSettings[index] };
            currentUserSettings[setting] = value;
            updatedUserSettings[index] = currentUserSettings;
            return { userSettings: updatedUserSettings };
        });
    };
    
    getAvailableOperations=()=>{        
        const availableOperations = Math.max(this.props.user.totalOperations - this.props.user.usedOperations, 0)
        return this.props.user.totalOperations === -1 ? 'Ilimitadas' : availableOperations
    }

    redertransferUser=(signer)=>{  
        return this.getAvailableOperations() !== 'Ilimitadas' && this.props.user.userName !== signer;
    }

    renderSigner = (signer, index, isMobile) => {

        const searchResults = signer?[...this.state.searchResults, { label: signer, value: signer }]:this.state.searchResults
        const uniqueSearchResults = searchResults.reduce((acumulador, valorActual) => {
            if (!acumulador.some(item => item.value === valorActual.value)) {
                acumulador.push(valorActual);
            }
            return acumulador;
        }, []);
        return <>
            <Row gutter={3} key={index + 'signer'} style={{ background: 'white', alignItems: 'center', rowGap: 10 }}>
                <Col style={{ textAlign: 'center' }} span={2}>
                    <img alt={''} src={keypad} />
                </Col>
                <Col span={isMobile ? 22 : 13}>
                    {this.props.user.includeUserList ? <AutoComplete
                        style={{ width: '100%', background: '#F7F9FC' }}
                        options={uniqueSearchResults}
                        onSelect={(value) => this.formatUserName(value, index)}
                        onSearch={this.searchValue}
                        onChange={(e) => this.formatUserName(e, index)}
                        value={signer}
                        placeholder={load_files[this.props.language].select_choose_user}
                    /> :
                        <Input onChange={(e) => this.formatUserName(e.target.value, index)} value={signer} style={{ background: '#F7F9FC' }} placeholder={load_files[this.props.language].select_choose_user} />}
                </Col>
                {isMobile && <Col span={2} />}
                <Col style={{ textAlign: 'center' }} span={isMobile ? 20 : 7}>
                    <Select placeholder={'Sesion'} className={'w-100'} style={{ textColor: '#222B45', fontSize: 14, fontWeight: 700, }} defaultValue={false}
                        onChange={(value) => this.handleUserSettings(index, value, 'getAppCode')} value={this.state.userSettings[index]?.getAppCode ?? false} >
                        <Select.Option value={false}><LockIcon style={{ verticalAlign: 'middle', marginRight: 10, paddingBottom: 3 }} />{load_files[this.props.language].withSesion}</Select.Option>
                        <Select.Option value={true}><UnlockIcon style={{ verticalAlign: 'middle', marginRight: 10, paddingBottom: 3 }} />{load_files[this.props.language].withoutSesion} </Select.Option>
                    </Select>
                </Col>
                <Col style={{ textAlign: 'center' }} span={2}>
                    <img alt={''} src={trashIcon} onClick={() => this.deleteSigner(signer)} />
                </Col>
            </Row>
            <Row gutter={3} key={index + 'transfer'} className='mt-10' style={{ background: 'white', alignItems: 'center' }}>
                <Col style={{ textAlign: 'center' }} span={2} />
                <Col span={22} style={!isMobile && { display: 'flex' }}>
                    {this.redertransferUser(signer) && <> <Switch onChange={(value) => this.handleUserSettings(index, value, 'transferOperations')} checked={this.state.userSettings[index]?.transferOperations ?? this.props.user.defaultTransfer}
                        checkedChildren={<CheckOutlined />} />
                    <span style={{ marginLeft: '10px', marginRight: '5px' }}>{load_files[this.props.language].transferOperations}</span>
                    <Typography className='size12' style={{ color: '#DB8B00', alignSelf: 'center' }}>{load_files[this.props.language].noteTransferOperations}</Typography></>}
                </Col>
            </Row>
        </>;
    }

    collapseItems=(isMobile, cardPadding)=>
    {

        return [
            {
                key: '1',
                style:{ paddingRight: cardPadding, paddingLeft: cardPadding, background: "#E4E9F2", height: '100%' },
                label: this.renderPanelHeader(load_files[this.props.language].documentsTitle, load_files[this.props.language].documentsDescription),
                children: <div>
                        <SignDropZoneField label={load_files[this.props.language].uploadText.toUpperCase()} label2={load_files[this.props.language].dragText}
                                           handleOnDrop={this.handleOnDrop} formFile={this.state.formFile} combineText={profile[this.props.language].combinePDF}
                                           deleteCard={this.handleDeleteFile} showTemplatesModal={this.showTemplatesModal} formTemplates={this.state.templates} deleteTemplate={this.handleDeleteTemplate} />
                        {(this.state.formFile && this.state.formFile.length) + (this.state.templates && this.state.templates.length) > 1 && <div style={{ marginTop: '10px' }}>
                            <Checkbox onChange={(e) => this.setState({ combinePDF: e.target.checked })}
                                      checked={this.state.combinePDF}>{profile[this.props.language].combinePDF}</Checkbox>
                            {this.state.combinePDF && !loadFileFieldsToHide.includes("combinedFileName") ? <Col span={24}>
                                <Tooltip title={load_files[this.props.language].combinedFileNameLabel} className='mt-10'>
                                    <Input value={this.state.combinedFileName}
                                           onChange={(e) => this.setState({ combinedFileName: e.target.value })} />
                                </Tooltip>
                            </Col> : <></>}
                        </div>}
                    </div>
            },
            {
                key: '2',
                style:{ paddingRight: cardPadding, paddingLeft: cardPadding, background: "#E4E9F2", height: '100%' },
                label: this.renderPanelHeader(load_files[this.props.language].signersTitle, load_files[this.props.language].signersDescription),
                children: <div>
                        <Col style={{ marginBottom: '30px' }} span={24}>
                            <Checkbox className='size15' onChange={(e) => this.setState({ onlySigner: e.target.checked })}
                                      checked={this.state.onlySigner}>{load_files[this.props.language].select_choose_userLabel}</Checkbox>
                        </Col>
                        <Row className='mb-20'>
                            {!this.state.onlySigner && <Col xs={24} md={10} lg={7}>
                                <Switch onChange={(e) => this.setState({ ordered: e, multipleWorkflow: !e && this.state.multipleWorkflow })} checked={this.state.ordered}
                                        checkedChildren={<CheckOutlined />} />
                                <span style={{ marginLeft: '10px', marginRight: '25px' }}>{load_files[this.props.language].orderedLabel}</span>
                            </Col>}
                            <Col xs={24} md={14} lg={12} >
                                <Switch onChange={(e) => this.setState({ locate: e })} checked={this.state.locate}
                                        checkedChildren={<CheckOutlined />} />
                                <span style={{ marginLeft: '10px' }}>{load_files[this.props.language].locate}</span>
                            </Col>
                        </Row>

                        {!this.state.onlySigner && this.state.signers &&
                        <Table
                            className='signers'
                            columns={this.columns(isMobile)}
                            dataSource={this.signersToTable()}
                            pagination={false}
                            rowKey={'key'}
                            components={{ body: { row: DraggableBodyRow } }}
                            onRow={(record, index) => {
                                return {
                                    index, moveRow: this.moveRow, entries: this.signersToTable(),
                                }
                            }}
                        />}

                        {!this.state.onlySigner && <Col span={24}>
                            <Button style={{ width: '150px', fontSize: '10px' }} type={'light-primary rounded-sm'} onClick={this.addSigner}>
                                {load_files[this.props.language].addSignerLabel.toUpperCase()}
                            </Button>
                        </Col>}
                    </div>
            },
            {
                key: '3',
                style:{ paddingRight: cardPadding, paddingLeft: cardPadding, background: "#E4E9F2", height: '100%' },
                label: this.renderPanelHeader(load_files[this.props.language].optionsTitle, load_files[this.props.language].sendOptionsLabel),
                children: <div>
                        {this.state.onlySigner || loadFileFieldsToHide.includes("optMessage") ? '' : <>
                            <Col span={24}>
                                <span className={'size12'}>{general[this.props.language].commentPlaceHolder.toUpperCase()}</span>
                                <TextArea value={this.state.optMessage} onChange={(e) => this.setState({ optMessage: e.target.value })} />
                            </Col>
                            <Col span={24}>
                                <img alt={''} src={infoIcon} />
                                <span style={{ marginLeft: '10px' }} className={'size12'}>{load_files[this.props.language].infoMessage}</span>
                            </Col></>}
                        {this.state.onlySigner ? '' : <Col style={this.state.onlySigner ? {} : { marginTop: '35px' }} span={24}>
                            <span className={'text-title size18'}>{load_files[this.props.language].copySubtitle}</span>
                        </Col>}
                        {this.state.onlySigner || loadFileFieldsToHide.includes("sendConfirmation") ? '' : <Col span={24}>
                            <Checkbox onChange={(e) => this.setState({ sendConfirmation: e.target.checked })}
                                      checked={this.state.sendConfirmation}>
                                {load_files[this.props.language].send_notification}
                            </Checkbox>
                        </Col>}
                        {this.state.onlySigner || loadFileFieldsToHide.includes("sendAll") ? '' : <Col span={24}>
                            <Checkbox onChange={(e) => this.setState({ sendAll: e.target.checked })}
                                      checked={this.state.sendAll}>{load_files[this.props.language].sendAll}</Checkbox>
                        </Col>}
                        {loadFileFieldsToHide.includes("copyToCheck") ? '' :
                            <Col span={24}>
                                <Checkbox onChange={(e) => {
                                    const checkValue = e.target.checked;
                                    this.setState({ copyToCheck: checkValue, copyTo: checkValue ? this.state.copyTo : '' })
                                }}
                                          checked={this.state.copyToCheck}>{load_files[this.props.language].sendCopyTo}
                                </Checkbox>
                            </Col>}
                        {this.state.copyToCheck ? <Col span={24} className='mt-10 mb-10'>
                            <Tooltip title={load_files[this.props.language].stringCopyTo}>
                                <Input value={this.state.copyTo}
                                       onChange={(e) => this.setState({ copyTo: e.target.value })} /></Tooltip>
                        </Col> : <></>}
                        {this.state.onlySigner || loadFileFieldsToHide.includes("multipleWorkflow") ? '' : <Col span={24}>
                            <Checkbox onChange={this.changeMultipleWorkflow} checked={this.state.multipleWorkflow}>
                                {load_files[this.props.language].multipleWorkflow}
                            </Checkbox>
                        </Col>}

                        {this.state.onlySigner || loadFileFieldsToHide.includes("remind") ? '' : <>
                            <Col style={{ marginTop: '35px' }} span={24}>
                                <span className={'text-title size18'}>{load_files[this.props.language].reminderSubtitle}</span>
                            </Col>
                            <Col span={24}>
                                <Checkbox
                                    onChange={(e) => this.updateRemindMaxDate(e.target.checked)}
                                    checked={this.state.remind}>{load_files[this.props.language].remind}
                                </Checkbox>
                            </Col>
                            {this.state.remind && <><Col span={24}>
                                <span style={{ marginRight: '10px' }}>{load_files[this.props.language].maxDate}</span>
                                <Select onChange={(value) => this.updateRemindMaxDate(true, value)} value={this.state.maxDate.diff(moment(), 'days') + 1}
                                        style={{ minWidth: '70px' }}>{this.createReminderOptions()}</Select>
                            </Col>
                                <Col span={24}>
                                    <span style={{ marginRight: '10px' }}>{load_files[this.props.language].remindEvery}</span>
                                    <Select onChange={(value) => this.setState({ remindEvery: value })} value={this.state.remindEvery}
                                            style={{ minWidth: '70px' }}>{this.createReminderOptions()}</Select>
                                    <span style={{ marginLeft: '10px' }}>{load_files[this.props.language].hours}</span>
                                </Col></>}</>}
                    </div>
            },
        ];
    };
    render() {
        const isMobile = this.props.dimApp.width <= sGrid, cardPadding = isMobile ? 5 : 70;        
        const totalOperations = this.getAvailableOperations();
        const colorPrimary = getCustomColors().colorPrimary;
        return <>
            <Card style={{ background: "#E4E9F2", height: '100%' }} bodyStyle={{ padding: 0 }} bordered={false}>
                <div style={{ ...{ display: 'flex', gap: 8, marginTop: 20 }, ...(isMobile ? { paddingLeft: 7 } : { paddingLeft: 70 }) }}>
                    <Typography>{load_files[this.props.language].totalOperations}</Typography>
                    <ShoppingBagIcon style={{ marginTop: 3 }} />
                    <Typography style={{ color: colorPrimary }}>{totalOperations}</Typography>
                </div>
                <Collapse accordion expandIcon={({ isActive }) => isActive ? <img alt={''} src={collapseUpIcon} /> : <img alt={''} src={collapseDownIcon} />}
                    expandIconPosition={'end'} defaultActiveKey={['1']} items={this.collapseItems(isMobile,cardPadding)}
                          style={isMobile ? { background: "#E4E9F2", marginBottom: 150 } : { background: "#E4E9F2", marginBottom: 95 }}>
                </Collapse>
                <Row className='footer-sign'>
                    <Col xs={24} sm={16} style={{ paddingLeft: cardPadding, display: 'flex' }} >
                        <Checkbox onChange={this.showsaveOption} checked={this.state.saveOption}
                            style={{ alignItems: 'center' }}>{signature[this.props.language].saveOption}</Checkbox>
                        <Button className='unstyled-button link' style={{ marginBottom: 0 }} onClick={this.showPreferenceModal}>{signature[this.props.language].showPreferences}</Button>
                    </Col>
                    <Col style={{ textAlign: 'right' }} xs={12} sm={4}>
                        <Button style={{ minHeight: '48px', width: '138px', marginBottom: 0 }} className='btn-secondary' /*type={'light-primary rounded-sm'}*/ 
                            onClick={() => this.props.dispatch(push(HOME_ROUTE))}>
                            {load_files[this.props.language].cancelSendLabel.toUpperCase()}
                        </Button>
                    </Col>
                    <Col style={{ textAlign: 'center' }} xs={12} sm={4}>
                        <SubmitButton style={{ minHeight: '48px', width: '138px', marginBottom: 0 }} type={"primary light-primary rounded-sm"}
                            label={load_files[this.props.language].continueLabel.toUpperCase()} onSubmit={this.submit} />
                    </Col>
                    {renderCopyRight(this.props.language, cardPadding)}
                </Row>
            </Card>
        </>;
    }
}

const mapStateToProps = state => {
    let queryParams = getQueryParams(state.router.location.search);
    return {
        language: state.auth.language,
        users: state.userReportList.users.map(user => { return { value: user.userName, label: user.userName } }),
        user: state.user,
        parentId: state.templateList.parentId,
        templates: state.templateList.entries,
        options: state.workflowList.workflowOptions,
        dimApp: state.locate.dimensionsApp,
        templateID: queryParams.templateID,
        templateName: queryParams.templateName,
        metadata: state.reportList.entries
    };
};

export default PrivateRoute(connect(mapStateToProps, {
    getAllUsers, getUserInfo, getPDFTemplatesInformation, getPDFTemplate,
    putFiles, getWorkflowOptions, saveWorkflowOption, deleteWorkflowOption, hideAllMenu, modalShow, modalDismiss, getAllMetadata
})(NewSign));