import React from 'react';
import { connect } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import QRCode from "react-qr-code";
import { Button, Col, Row, Checkbox, Tooltip, Spin } from "antd";
import signature from '../../const/signature';
import { SIGNATUREQR_ROUTE } from '../Root/routes';
import { getQueryParams, showFull } from '../Helpers/utils';
import { useSignaturePAD } from '../../apis/generalValues'
import SignatureHandleOptions from './SignatureHandleOptions';
import smartphone from '../../resources/img/smartphone.png';
import stepOverPad from '../../resources/img/stepoverpad.png';
import { EditOutlined, CheckOutlined, QrcodeOutlined, FormOutlined, ClearOutlined, SaveOutlined } from '@ant-design/icons';
import updateHWImage from '../../actions/signature/updateHWImage';
import setHWImage from '../../actions/signature/setHWImage';
import getHWImage from '../../actions/signature/getHWImage';
import { maxWidth } from "../Helpers/utils";
import { ReactComponent as EmptyTemplatesIcon } from '../../resources/img/NewIcons/templates/emptyTemplatesIcon.svg'
import { Typography } from 'antd';

const isPageMobile = window.location.href.includes(SIGNATUREQR_ROUTE);
class SignatureHandwritten extends React.Component {
    //handSigTypes 1 --> draw, 2 -->QR, 3 -->Signature PAD
    state = { heightPad: 0, widthPad: 0, urlQR: '', padOverwrite: false, handSigType: 1, showFinishSignatureQR: false }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        setTimeout(() => {
            if (this.props.padData && this.sigPad)
                this.sigPad.fromData(this.props.padData);
        }, 50);
        this.initTimerStates();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentInfoImageQR = this.props.signatureFiles.infoImageQR;
        if (currentInfoImageQR.base64 && prevProps.signatureFiles.infoImageQR.base64 !== currentInfoImageQR.base64 &&
            currentInfoImageQR && currentInfoImageQR.state === 'drawing' && this.props.useImage === '2') {
            this.props.modalDismiss();
        }
        if (currentInfoImageQR && prevProps.signatureFiles.infoImageQR.state !== currentInfoImageQR.state) {
            if (currentInfoImageQR.state === 'cancelled') {
                this.clearIntervals();
                if (this.props.onSubmit)
                    this.props.onSubmit()
                if (!isPageMobile)
                    this.initTimerStates()
            }
            if (currentInfoImageQR.state === 'finished') {
                this.clearIntervals();
                this.props.modalDismiss();
            }
            this.handleResize()
        }
        if (prevProps.useImage !== this.props.useImage) {
            if (this.props.useImage === '2')
                this.initTimerStates();
            else {
                this.clearIntervals();
                this.props.updateHWImage(JSON.stringify({ state: "cancelled" }))
            }
        }
        if (prevProps.hwimgid !== this.props.hwimgid && this.props.hwimgid)
            this.initTimerStates();

        if (!prevProps.signatureFiles.loadingPad && this.props.signatureFiles.loadingPad) {
            this.initTimerStates();
        }
    }

    componentWillUnmount() {
        this.setState({ urlQR: '' });
        this.clearIntervals();
        window.removeEventListener('resize', this.handleResize)
    }

    initTimerStates = async () => {
        if (isPageMobile)
            this.timerSendImage = setInterval(() => this.sendImage(), 1000);
        else {
            if (this.props.signatureFiles.hwimgid && this.props.signatureFiles.infoImageQR.state !== 'drawingPad') {
                await this.props.getHWImage({ "id": this.props.signatureFiles.hwimgid }, this.props.signatureFiles.infoImageQR.state);
                const urlQR = window.location.origin + window.location.pathname + '#' + SIGNATUREQR_ROUTE + '?hwimgid=' + this.props.hwimgid;
                console.log(urlQR)
                this.setState({ urlQR: urlQR, padOverwrite: this.props.padOverwrite },
                    () => {
                        if (this.props.signatureFiles.infoImageQR.state !== 'drawing' && this.props.signatureFiles.infoImageQR.state !== 'drawingPad')
                            this.props.setTimerGetImageQR();
                    }
                )
            }
        }
        this.handleResize();
    }

    handleResize = () => {
        setTimeout(function () {
            const widthApp = window.innerWidth
            const heightApp = window.innerHeight
            const isVerticalTabs = widthApp >= maxWidth || (widthApp > heightApp);
            const fixHeight = isPageMobile ? 100 : 40;
            const fixHeader = this.headerCanvas ? this.headerCanvas.clientHeight : 0;
            const fixFooter = this.footerCanvas ? this.footerCanvas.clientHeight : (showFull() || isPageMobile ? 30 : 0);
            const fixBtnOptions = this.btnOptionsHandWritten ? this.btnOptionsHandWritten.clientHeight + (isVerticalTabs ? 43 : 100) : 0;
            const heightPad = (this.columnQRPad ? this.columnQRPad.clientHeight : (heightApp - (isPageMobile ? 0 : fixBtnOptions))) - fixHeight - fixHeader - fixFooter;
            const widthPad = (this.colCanvas ? this.colCanvas.clientWidth : widthApp) - (showFull() ? 25 : 8) - (isPageMobile ? 20 : 0) - (isVerticalTabs ? 6 : 0);
            this.setState({ heightPad, widthPad });
        }.bind(this), 100)
    };

    onStartPadSigning = () => {
        if (this.props.onStartPadSigning)
            this.props.onStartPadSigning();
        if (this.props.modalDismiss)
            this.props.modalDismiss();
    }

    clearIntervals = () => {
        clearInterval(this.timerSendImage);
        this.timerSendImage = null
        if (!this.props.signatureFiles.infoImageQR.base64 && this.props.clearTimerGetImageQR)
            this.props.clearTimerGetImageQR();
    }

    trimImage = async (state) => {
        if (isPageMobile)
            await this.sendImage(state)
        this.clearIntervals();
        this.props.modalDismiss();
        const sigPad = this.sigPad;
        let data = "";
        if (sigPad)
            data = sigPad.toData();
        //Permite visualizar un mensaje despues de la firma desde el dispositivo
        this.setState({ showFinishSignatureQR: true })
        this.props.onSubmit(this.getCanvasImage(), data);
    };

    clear = () => {
        this.sigPad.clear();
    }

    getCanvasImage = () => {
        if (!this.sigPad)
            return "";
        return this.sigPad.getCanvas().toDataURL('image/png').replace("data:image/png;base64,", "");
    }

    sendImage = async (state = "drawing") => {
        const base64 = this.getCanvasImage();
        const info = { state, base64 };
        const data = {
            id: this.props.params.hwimgid,
            info: JSON.stringify(info)
        }
        await this.props.setHWImage(data);
    }

    changeSaveImage = () => {
        this.props.handlePadOverwriteChange();
        this.setState({ padOverwrite: !this.state.padOverwrite })
    }

    getStyleOptionButtons = (pressed, disabled = null) => {
        if (pressed)
            return 'button-pressed'
        else if (disabled)
            return 'button-disabled'
        else
            return 'button-enabled'
    }

    renderOptionsHandwritten = () => {
        const isHandWritten = this.state.handSigType === 1;
        if (!isPageMobile && !showFull())
            return (<div ref={(ref) => this.btnOptionsHandWritten = ref} style={{ textAlign: 'center' }} >
                <Tooltip title={signature[this.props.language].signatureTitleTypeID[2]}>
                    <Button className={this.getStyleOptionButtons(isHandWritten)} style={{ marginRight: '10px' }} icon={<EditOutlined />} type={'primary sign-btn'} htmlType={'button'}
                        onClick={() => this.setState({ handSigType: 1 })} />
                </Tooltip>
                <Tooltip title={signature[this.props.language].handwrittenSignatureOptions[0]}>
                    <Button className={this.getStyleOptionButtons(this.state.handSigType === 2)} style={{ marginRight: '10px' }} icon={<QrcodeOutlined />}
                        type={'primary sign-btn'} htmlType={'button'} onClick={() => this.setState({ handSigType: 2 })} />
                </Tooltip>
                {useSignaturePAD ? this.renderBtnPad(() => this.setState({ handSigType: 3 }, () => this.onStartPadSigning())) : null}
                <div className={'divider-buttons'} style={{ height: '2.9em', marginRight: '10px' }} />
                <Tooltip title={this.state.padOverwrite ? signature[this.props.language].saveHandwrittenSignatureDisable : signature[this.props.language].saveHandwrittenSignatureEnable}>
                    <Button className={this.getStyleOptionButtons(this.state.padOverwrite)} style={{ marginRight: '10px' }} icon={<SaveOutlined />} type={'primary sign-btn'}
                        htmlType={'button'} onClick={() => this.changeSaveImage()} />
                </Tooltip>
                {isHandWritten ? <Tooltip title={signature[this.props.language].clear}>
                    <Button className={this.getStyleOptionButtons(false)} style={{ marginRight: '10px' }} icon={<ClearOutlined />} type={'primary sign-btn'}
                        htmlType={'button'} onClick={this.clear} />
                </Tooltip> : null}
                {isHandWritten ? <Tooltip title={signature[this.props.language].accept}>
                    <Button className={this.getStyleOptionButtons(false)} style={{ marginRight: '10px' }} icon={<CheckOutlined />} type={'primary sign-btn'}
                        htmlType={'button'} onClick={() => this.trimImage('finished')} />
                </Tooltip> : null}
            </div>)
    }

    renderBtnPad = (action) => {
        const disabledPad = typeof this.props.signatureFiles.loadingPad === 'undefined';
        return <Tooltip title={disabledPad ? signature[this.props.language].disabledPad : signature[this.props.language].enabledPad}>
            {this.props.signatureFiles && this.props.signatureFiles.loadingPad ? <Spin style={{ marginRight: 10 }} /> :
                <Button className={this.getStyleOptionButtons(false, disabledPad)} style={{ marginRight: '10px' }} icon={<FormOutlined />} disabled={disabledPad}
                    type={'primary sign-btn'} htmlType={'button'} onClick={action} />}
            <></>
        </Tooltip>
    }

    renderQRPad = () => {
        const showFullValue = showFull();
        if (!isPageMobile && (showFullValue || this.state.handSigType === 2 || this.state.handSigType === 3))
            return <Col xs={24} md={(showFullValue ? 9 : 24)} ref={(columnQRPad) => { this.columnQRPad = columnQRPad }}
                style={{ height: '100%' }}>
                {this.renderOptionQR()}
                {this.renderOptionPad()}
            </Col>;
    }

    renderOptionQR = () => {
        if ((showFull() || this.state.handSigType === 2))
            return <div className={'signerBorder'} style={{ marginRight: 10, padding: 10, textAlign: 'center', marginBottom: showFull() ? 0 : 10 }}>
                <h2 className={'title'} style={{ textAlign: 'left' }}>{signature[this.props.language].handwrittenSignatureOptions[0]}</h2>
                <div className={'signerBorder'} style={{ borderBottom: 'none', marginBottom: 10 }} />
                <p style={{ textAlign: 'left' }}>{signature[this.props.language].scanQR}</p>
                <QRCode size={125} value={this.state.urlQR} />
                <p style={{ textAlign: 'justify', fontSize: '11px' }}>
                    {signature[this.props.language].signatureTextTypeID[2].instructions[0]}<br />
                    {signature[this.props.language].signatureTextTypeID[2].instructions[1]}<br />
                    {signature[this.props.language].signatureTextTypeID[2].instructions[2]}<br />
                </p>
            </div>;
    }

    renderOptionPad = () => {
        if (useSignaturePAD && (showFull() || this.state.handSigType === 3))
            return <Row className={'signerBorder'} style={{ marginRight: 10, marginTop: 10, padding: 10, }}>
                <Col xs={24} md={18}>
                    <h2 className={'title'} style={{ textAlign: 'left' }}>{signature[this.props.language].handwrittenSignatureOptions[1]}</h2>
                </Col>
                <Col xs={24} md={6} style={{ textAlign: 'right' }}>
                    {this.renderBtnPad(this.onStartPadSigning)}
                </Col>
                <div className={'signerBorder'} style={{ borderBottom: 'none', marginBottom: 10, width: '100%' }} />
            </Row>;
    }

    renderCanvas = () => {
        const showFullValue = showFull();
        const showFullOrMobile = showFullValue || isPageMobile
        if ((showFullValue || this.state.handSigType === 1) && this.state.showFinishSignatureQR === false)
            return <Col ref={(ref) => this.colCanvas = ref} xs={24}
                md={!isPageMobile && showFullValue ? 15 : 24} style={showFullOrMobile ? { padding: 10, border: null } : { padding: 0, border: 'none' }}>
                {showFullOrMobile ? <div ref={(ref) => this.headerCanvas = ref} style={{ paddingBottom: 10 }}>
                    <h2 className={'title'} style={{ textAlign: 'left', marginTop: 5, marginBottom: 5 }}>{signature[this.props.language].handwrittenSignatureOptions[2]}</h2>
                </div> : null}
                {this.state.heightPad > 0 && this.state.widthPad > 0 ? <><SignatureCanvas throttle={30} minDistance={0} dotSize={1} maxWidth={1} minWidth={1}
                    ref={(ref) => this.sigPad = ref} canvasProps={{ height: this.state.heightPad, width: this.state.widthPad, className: 'canvasBorder' }} />
                    <Row ref={(ref) => this.footerCanvas = ref} style={{ justifyContent: 'space-between' }} >
                        {isPageMobile ? <Button htmlType={'button'} style={{ marginBottom: 0 }}
                            type={'primary light-primary rounded-sm float-right'}
                            onClick={() => this.trimImage('cancelled')}>{signature[this.props.language].cancel}</Button> : <></>}
                        {showFullOrMobile ? <Button htmlType={'button'} style={{ marginBottom: 0 }}
                            type={'primary light-primary rounded-sm float-right'}
                            onClick={this.clear}>{signature[this.props.language].clear}</Button> : null}
                        {showFullOrMobile ? <Button htmlType={'button'} style={{ marginBottom: 0 }}
                            type={'primary light-primary rounded-sm float-right'}
                            onClick={() => this.trimImage('finished')}>
                            {isPageMobile ? signature[this.props.language].title : signature[this.props.language].accept}</Button> : null}
                    </Row>
                </> : <></>}
            </Col>;
        else
            return <div className="text-center" style={{ width: '100%', padding:0, display:'flex', justifyContent:'center' }}>
                <EmptyTemplatesIcon />
                <Typography className='text-title' style={{alignContent:'center', marginLeft:20}} >{signature[this.props.language].continueWeb}</Typography>
            </div>
    }

    renderCheckSaveSignature = () => {
        if (!isPageMobile && showFull())
            return <div className={'signerBorder'} style={{ marginTop: 10, padding: 10, }}>
                <Checkbox defaultChecked checked={this.state.padOverwrite}
                    onChange={this.changeSaveImage}>{signature[this.props.language].saveHandwrittenSignature}</Checkbox>
            </div>;
    }

    render() {
        const drawState = this.props.signatureFiles.infoImageQR.state;
        if (drawState === 'drawing' || drawState === 'drawingPad') {
            const tittle = drawState === 'drawing' ? signature[this.props.language].handwrittenSignatureMobile : signature[this.props.language].handwrittenSignaturePad;
            const text = drawState === 'drawing' ? signature[this.props.language].messageUseSignatureMobile : signature[this.props.language].messageUseSignaturePad
            const image = drawState === 'drawing' ? smartphone : stepOverPad;
            return <SignatureHandleOptions title={tittle} text={text} image={image} textButton={signature[this.props.language].accept}
                modalDismiss={this.props.modalDismiss} />;
        }
        else {
            return <>
                {this.renderOptionsHandwritten()}
                <Row>
                    {this.renderQRPad()}
                    {this.renderCanvas()}
                </Row>
                {this.renderCheckSaveSignature()}
            </>;
        }
    }
}

const mapStateToProps = state => {
    return {
        signatureFiles: state.signatureFiles,
        language: state.auth.language,
        params: getQueryParams(state.router.location.search),
        useImage: state.user.useImage
    };
};

export default connect(mapStateToProps, { getHWImage, setHWImage, updateHWImage })(SignatureHandwritten);