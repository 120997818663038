import React from 'react';
import { connect } from "react-redux";
import { Button, Drawer, Layout, Menu, Typography } from 'antd';
import authorization from "../../../../authorization";
import './PrivateSider.css';
import logOut from "../../../../actions/auth/logoutUser";
import { push } from 'connected-react-router';
import {
  ADMIN_ROUTE, CERTIFICATES_ROUTE, REPORTS_ROUTE, SYSTEM_STATUS_ROUTE,
  TEMPLATES_ROUTE, VERIFICATION_ROUTE, HOME_ROUTE, MANAGEMENT_ROUTE,
  ECOM_ROUTE, CHECK_ECOM_ROUTE, SEND_AND_SIGN_ROUTE, ACTIVATE_ROUTE,
  SUBSCRIPTION_ROUTE, NEW_PENDING_FILES_ROUTE, WORKFLOW_FILES_ROUTE,
} from "../../../Root/routes";
import layout from "../../../../const/layout";
import changeLanguage from "../../../../actions/auth/changeLanguage";
import hideOptions from "../../../../apis/hideOptions";
import {
  getCustomLogos,
  showFullMenus,
  siderWidth,
  renderLanguageOptions,
  renderCertificate,
  minHeightMenuBottom,
  getMenuItems
} from "../../../Helpers/utils";
import { ReactComponent as HomeIcon } from "../../../../resources/img/NewIcons/siderMenu/home.svg"
import { ReactComponent as ManagementIcon } from "../../../../resources/img/NewIcons/siderMenu/management.svg"
import { ReactComponent as TemplatesIcon } from "../../../../resources/img/NewIcons/siderMenu/templates.svg"
import { ReactComponent as VerifyIcon } from "../../../../resources/img/NewIcons/siderMenu/verifications.svg"
import { ReactComponent as CetificateIcon } from "../../../../resources/img/NewIcons/siderMenu/certificate.svg"
import { ReactComponent as ReportsIcon } from "../../../../resources/img/NewIcons/siderMenu/reports.svg"
import { ReactComponent as StateAPIIcon } from "../../../../resources/img/NewIcons/siderMenu/api-state.svg"
import { ReactComponent as ShoppingIcon } from "../../../../resources/img/NewIcons/siderMenu/shopping.svg"
import { ReactComponent as ConfigurationsIcon } from "../../../../resources/img/NewIcons/siderMenu/configurations.svg"
import showMenu from '../../../../actions/info/showMenu';
import showSiderDrawer from "../../../../actions/info/showSiderDrawer";
import shortcuts from "../../../../const/shortcuts";
import { ReactComponent as BandejaEntradaIcon } from "../../../../resources/img/NewIcons/shortcuts/bandeja-entrada.svg";
import { ReactComponent as BandejaSalidaIcon } from "../../../../resources/img/NewIcons/shortcuts/bandeja-salida.svg";
import { ReactComponent as CompletadosIcon } from "../../../../resources/img/NewIcons/shortcuts/completados.svg";
import { ReactComponent as SmallLogoIcon } from '../../../../resources/img/NewIcons/siderMenu/small-logo.svg';
import editIcon from '../../../../resources/img/NewIcons/siderMenu/edit.svg'
import { Tooltip } from 'antd';

const { Sider } = Layout;

class NewPrivateSider extends React.Component {

  state = { openKeys: [MANAGEMENT_ROUTE] }
  getSiderItems = (onlyIcons) => {
    const menuBottom = this.props.dimApp.height >= minHeightMenuBottom;
    const certNotRequired = !this.props.requiresCert, pathName = this.props.pathname;
    const shopPaths = [ECOM_ROUTE, CHECK_ECOM_ROUTE, ACTIVATE_ROUTE, SUBSCRIPTION_ROUTE];
    let activateKey = ACTIVATE_ROUTE;
    if (shopPaths.includes(pathName))
      activateKey = pathName;
    const items = [
      { key: HOME_ROUTE, icon: HomeIcon, visible: true },
      {
        key: 'management', icon: ManagementIcon, visible: certNotRequired, children: [
          { key: NEW_PENDING_FILES_ROUTE, label: onlyIcons ? '' : shortcuts[this.props.language].shortCuts_path[NEW_PENDING_FILES_ROUTE], icon: <Tooltip title={onlyIcons ? shortcuts[this.props.language].shortCuts_path[NEW_PENDING_FILES_ROUTE] : ''}><BandejaSalidaIcon className={onlyIcons ? 'submenu-only-icons' : 'submenu-icon'} /></Tooltip> },
          { key: MANAGEMENT_ROUTE, label: onlyIcons ? '' : shortcuts[this.props.language].shortCuts_path[MANAGEMENT_ROUTE], icon: <Tooltip title={onlyIcons ? shortcuts[this.props.language].shortCuts_path[MANAGEMENT_ROUTE] : ''}><BandejaEntradaIcon className={onlyIcons ? 'submenu-only-icons' : 'submenu-icon'} /></Tooltip> },
          { key: WORKFLOW_FILES_ROUTE, label: onlyIcons ? '' : shortcuts[this.props.language].shortCuts_path[WORKFLOW_FILES_ROUTE], icon: <Tooltip title={onlyIcons ? shortcuts[this.props.language].shortCuts_path[WORKFLOW_FILES_ROUTE] : ''}><CompletadosIcon className={onlyIcons ? 'submenu-only-icons' : 'submenu-icon'} /></Tooltip> }],
        label: layout[this.props.language].sider_management
      },
      { key: TEMPLATES_ROUTE, icon: TemplatesIcon, visible: certNotRequired },
      { key: VERIFICATION_ROUTE, icon: VerifyIcon, visible: certNotRequired && !hideOptions.includes("verify") },
      { key: REPORTS_ROUTE, icon: ReportsIcon, visible: certNotRequired && !hideOptions.includes("reports") },
      { key: CERTIFICATES_ROUTE, icon: CetificateIcon, visible: !hideOptions.includes("certs") },
      { key: activateKey, icon: ShoppingIcon, visible: certNotRequired && this.props.enableecom, posBottom: menuBottom ? 192 : null },
      { key: SYSTEM_STATUS_ROUTE, icon: StateAPIIcon, visible: certNotRequired && this.props.admin, posBottom: menuBottom ? 141 : null },
      { key: ADMIN_ROUTE, icon: ConfigurationsIcon, visible: certNotRequired && this.props.admin, posBottom: menuBottom ? 90 : null }

    ];
    return getMenuItems(items, layout[this.props.language].sider_path, 'new-netco-icon', pathName, onlyIcons);
  }

  handleItemClick = (event) => {
    if (this.props.pathname !== event.key)
      this.props.push(event.key);
    this.props.showSiderDrawer(false);
  }

  onOpenChange = (keys) => {
    //this.handleItemClick({key:MANAGEMENT_ROUTE});
    this.setState({ openKeys: keys });
    /*const latestOpenKey = keys.find((key) => this.state.openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }*/
  }

  renderFooter = (onlyIcons) => {
    if (onlyIcons)
      return ''
    else
      return <div className='div-footer' style={{ position: 'absolute', botom: 0 }}>
        <Typography className='size12' >
            {layout[this.props.language].footer}
            <Button className='link size12' style={{ padding: '1px 0px 0px 4px', margin: 0 }} onClick={() => (window.open('https://www.netcosigner.com/', '_blank'))}>
            {layout[this.props.language].footer1}
          </Button>
        </Typography>
      </div>
  }

  renderMenu = () => {
    const onlyIcons = showFullMenus(this.props.dimApp.width) && !this.props.visibleMenu
    return <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div className='menu'>
        {onlyIcons ? <SmallLogoIcon style={{ width: 28, height: 28 }} /> : <img className={'sider-logo'} src={getCustomLogos().base64Logo} alt="" />}
        {!showFullMenus(this.props.dimApp.width) && this.props.caID ? renderCertificate(this.props.caDescription, this.props.push, this.props.language, { marginTop: 34 }) : null}
        <div style={{ width: '100%' }}>
          <Button type="primary" htmlType="submit" onClick={() => { this.props.push(SEND_AND_SIGN_ROUTE); this.props.showSiderDrawer(false) }}
            style={onlyIcons ? { width: 34, padding: 0 } : { width: 149, padding: 0 }} className='text-title size12 mt-20'>
            {onlyIcons ? <img src={editIcon} alt='' /> : layout[this.props.language].sider_button_sign.toUpperCase()}
          </Button>
        </div>
      </div>
      <div style={{ flex: 1, overflowY: 'auto', marginBottom: 20 }}>
        <Menu theme={'dark'}
          mode="inline"
          defaultSelectedKeys={['/']}
          openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
          selectedKeys={[this.props.pathname]}
          className='mt-10 mb-70'
          onClick={this.handleItemClick} items={this.getSiderItems(onlyIcons)} />
      </div>
      <div>
        {this.renderFooter(onlyIcons)}
        {!showFullMenus(this.props.dimApp.width) ? renderLanguageOptions(this.props.changeLanguage, this.props.language, { position: 'absolute', bottom: 10, right: 10 }) : null}
      </div>
    </div>
  }


  render() {
    const showMenus = showFullMenus(this.props.dimApp.width);
    if (!showMenus)
      return (
        (<Drawer open={this.props.visibleSiderDrawer} closable={true}
          onClose={() => {
            this.props.showSiderDrawer(false);
          }}
          placement={'left'}>
          {this.renderMenu()}
        </Drawer>)
      );
    if (showMenus && !this.props.hideAllMenu) {
      return (
        <Sider width={this.props.visibleMenu ? siderWidth : 60} className='sider-menu-vertical'>
          {this.renderMenu()}
        </Sider>
      );
    }
    return <></>
  }
}

function mapStateToProps(state) {
  return {
    pathname: state.router.location.pathname,
    admin: state.auth.admin,
    requiresCert: state.auth.requiresCert,
    language: state.auth.language,
    enableecom: state.auth.enableecom,
    dimApp: state.locate.dimensionsApp,
    visibleMenu: state.locate.visibleMenu,
    visibleSiderDrawer: state.locate.visibleSiderDrawer,
    caID: state.user.caID,
    caDescription: state.user.caDescription,
    hideAllMenu: state.locate.hideAllMenu
  }
}

export default authorization(connect(mapStateToProps, { logOut, push, showMenu, changeLanguage, showSiderDrawer })(NewPrivateSider));